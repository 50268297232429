import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { SbxBarChartLine } from "../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import moment from "moment";
import BasicLanguage from "../../languages/Basic";
import PropTypes from "prop-types";
import { getWeekNumber } from "../../utils/GetWeeks";

export default function PeopleCountSensorChart({
  date,
  dateDisplay,
  peopleCountSensorData,
  sensorIds,
}) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    peopleCountSensorData = generateDataByDay(
      peopleCountSensorData,
      sensorIds,
      date
    );
  } else if (dateDisplay === "week") {
    peopleCountSensorData = generateDataByWeek(
      peopleCountSensorData,
      sensorIds,
      date
    );
  } else if (dateDisplay === "month") {
    peopleCountSensorData = generateDataByMonth(
      peopleCountSensorData,
      sensorIds,
      language,
      date
    );
  }

  return (
    <Stack sx={{ width: "100%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.peopleCountSensor.title[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ width: "100%", height: "300px" }}>
        <SbxBarChartLine data={peopleCountSensorData} dataKeys={sensorIds} />
      </Box>
    </Stack>
  );
}

PeopleCountSensorChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  peopleCountSensorData: PropTypes.array.isRequired,
  sensorIds: PropTypes.array.isRequired,
};

const generateDataByDay = (sensorHistory, sensorIds, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = {};
    for (let j = 0; j < sensorIds.length; j++) {
      defaultDays[i][sensorIds[j]] = 0;
    }
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      const date = moment(history.createdAt).date();
      sensorHistoryObject[date][history.deviceId] =
        sensorHistoryObject[date][history.deviceId] + history.value;
      return sensorHistoryObject;
    },
    defaultDays
  );

  return Object.keys(sensorHistoryPeopleCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        ...sensorHistoryPeopleCount[key],
      };
    });
};

const generateDataByWeek = (sensorHistory, sensorIds, date) => {
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByWeek:", date);
      throw new Error("Invalid date provided to generateDataByWeek");
    }
  }

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const firstWeek = getWeekNumber(startOfMonth);
  const lastWeek = getWeekNumber(endOfMonth);
  const numberOfWeeks = getTotalWeeksInYear(date.getFullYear());

  const defaultWeeks = {};
  for (let i = firstWeek; i <= lastWeek; i++) {
    const weekNumber = i === 0 ? 1 : i;
    defaultWeeks[weekNumber] = { NoValue: 0 };
    sensorIds.forEach((sensorId) => {
      defaultWeeks[weekNumber][sensorId] = 0;
    });
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      if (!history.createdAt || !history.deviceId || !history.value) {
        console.error("Invalid sensor history entry:", history);
        return sensorHistoryObject;
      }
      const historyDate = new Date(history.createdAt);
      if (isNaN(historyDate.getTime())) {
        console.error("Invalid history date:", history);
        return sensorHistoryObject;
      }

      const week = getWeekNumber(historyDate);

      if (
        sensorHistoryObject[week] &&
        history.deviceId in sensorHistoryObject[week]
      ) {
        sensorHistoryObject[week][history.deviceId] += history.value;
      } else {
        console.error(
          "Unexpected week or device ID in history:",
          week,
          history.deviceId
        );
        console.log(
          history.deviceId,
          "sensr history object inside the loop",
          history.deviceId,
          "history object setting "
        );
      }

      return sensorHistoryObject;
    },
    defaultWeeks
  );

  return Object.keys(sensorHistoryPeopleCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        ...sensorHistoryPeopleCount[key],
      };
    });
};

// Utility function to get the total weeks in a year
const getTotalWeeksInYear = (year) => {
  const lastDayOfYear = new Date(year, 11, 31); // December 31st
  return getWeekNumber(lastDayOfYear);
};

const generateDataByMonth = (sensorHistory, sensorIds, language, date) => {
  const defaultMonth = { month: {} };
  for (let i = 0; i < sensorIds.length; i++) {
    defaultMonth.month[sensorIds[i]] = 0;
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      sensorHistoryObject.month[history.deviceId] += history.value;
      return sensorHistoryObject;
    },
    defaultMonth
  );

  return Object.keys(sensorHistoryPeopleCount).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.peopleCountAll.yearMonthFormat[language]
      ),
      ...sensorHistoryPeopleCount[key],
    };
  });
};
