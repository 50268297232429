import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useLoaderData, useNavigate, useRouteError } from "react-router";
import Colors from "../components/utils/Colors";
import BasicLanguage from "../components/languages/Basic";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import { LanguageOutlined } from "@mui/icons-material";
import Typography from "../components/parts-ui/Typography";
import {
  requestGet,
  requestGetRobots,
  requestSend,
} from "../components/utils/functions";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import StorageImage from "../components/parts/StorageImage";
import dayjs from "dayjs";
import PropTypes from "prop-types";
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const commentState = atom({
  key: "requestComment",
  default: "",
});

const imageState = atom({
  key: "image",
  default: "",
});

export const loadRequest = async ({ params }) => {
  const { qrId } = params;
  let isRedirect;

  const request = await requestGet({ id: qrId }).then((result) => {
    const { status, content } = result.data;
    if (status === "success") {
      console.log(content);
      return content;
    } else if (status === "redirect") {
      isRedirect = true;
      return {};
    }
    throw new Error("cannot get data");
  });

  let qrRobots = null;
  if (request.robotAreas?.length > 0) {
    const robotIds = request.robotAreas.map((robotArea) => robotArea.robotId);
    qrRobots = await requestGetRobots({ qrId: qrId, robotIds: robotIds }).then(
      (result) => {
        if (result.data.status !== "success") {
          throw new Error("cannot get data");
        }
        return result.data.content;
      }
    );
  }

  return {
    isRedirect,
    qrId,
    request,
    qrRobots,
  };
};

export const RequestPage = () => {
  const { isRedirect, qrId, request, qrRobots } = useLoaderData();
  const theme = useTheme();
  const [selectedRequest, setSelectedRequest] = useState("");
  const [progress, setProgress] = useState(false);

  const navigate = useNavigate();
  const [language, setLanguage] = useRecoilState(languageState);

  const comment = useRecoilValue(commentState);
  const image = useRecoilValue(imageState);

  const showRobotCleaning = isCleaningRobotAvailible(
    qrRobots,
    request?.robotAreas,
    request?.cleaningMode
  );

  const [robotCleaningRequest, setRobotCleaningRequest] = useState(false);

  useEffect(() => {
    if (isRedirect) {
      return navigate(`../${qrId}/survey`);
    }
  }, [isRedirect, navigate, qrId]);

  const send = () => {
    if (robotCleaningRequest && !showRobotCleaning) {
      alert(BasicLanguage.alert.noRobots[language]);
      return;
    }
    setProgress(true);
    requestSend({
      request: selectedRequest,
      qrId: qrId,
      image: image,
      commentFromUser: comment,
      ...(robotCleaningRequest && {
        robotAreas: request.robotAreas,
      }),
    })
      .then((result) => {
        if (result.data.status === "success") {
          navigate(`../${qrId}/survey`);
        } else {
          alert(BasicLanguage.alert.errorOccurred[language]);
          setProgress(false);
        }
      })
      .catch((e) => console.error(e));
  };

  const handleSelectedRequestChange = (e) => {
    setSelectedRequest(e.target.value);
    setRobotCleaningRequest(e.target.name === "robotCleaning");
  };

  const qrRequestColor = request?.qrRequestColor;

  if (!isRedirect) {
    return (
      <Box
        sx={{ width: "100%", minHeight: "100dvh" }}
        backgroundColor={qrRequestColor || Colors.primary.main}
      >
        <Stack direction="row" sx={{ my: 3, mx: 2 }}>
          <Box sx={{ width: "20%" }}></Box>
          <Box sx={{ width: "60%", textAlign: "center" }}>
            {request.logo ? (
              <StorageImage imagePath={request.logo} sx={{ width: 200 }} />
            ) : (
              <img
                src="/logo-removebg-preview.png"
                style={{ width: 200 }}
                alt="logo"
              />
            )}
          </Box>
          <Box sx={{ width: "20%", display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                textDecoration: "underline",
                color: theme.palette.common.white,
              }}
              onClick={() => setLanguage(language === "en" ? "ja" : "en")}
            >
              <LanguageOutlined sx={{ mr: 0.5 }} />
              {BasicLanguage.languages[language]}
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            maxWidth: "400px",
            width: "80%",
            margin: "0px auto",
            color: "primary.main",
          }}
        >
          <Box
            sx={{
              backgroundColor: Colors.white,
              pt: 3,
              pb: 3,
              ...(qrRequestColor && { color: "#999999" }),
            }}
          >
            <Box sx={{ display: "block" }}>
              <Box
                sx={{
                  color: "#999999",
                  backgroundColor: "#F5F5F5",
                  p: 2,
                  textAlign: "center",
                  ml: 3,
                  mr: 3,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {request.facility}
                </Typography>
                <Typography>{request.place}</Typography>
              </Box>

              {/* //リクエストQRみだし */}
              <Typography sx={{ textAlign: "center", mt: 3, mb: 3 }}>
                {request?.QrSurveyTitleHeading === "" ||
                !request?.QrSurveyTitleHeading
                  ? BasicLanguage.request.chooseRequest[language]
                  : request.QrSurveyTitleHeading}
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  value={selectedRequest}
                  onChange={handleSelectedRequestChange}
                >
                  {request.requestsList?.map((requestItem) => (
                    <RequestButton
                      key={requestItem}
                      label={requestItem}
                      language={language}
                      selectedRequest={selectedRequest}
                      name={requestItem}
                      backgroundColor={qrRequestColor}
                      request={request}
                    />
                  ))}

                  {showRobotCleaning && (
                    <RequestButton
                      label={BasicLanguage.request.robotCleaning[language]}
                      language={language}
                      selectedRequest={selectedRequest}
                      name="robotCleaning"
                      backgroundColor={qrRequestColor}
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <Stack spacing={2} sx={{ mx: 3, mt: 4 }}>
                <Button
                  variant="contained"
                  onClick={send}
                  disabled={
                    !selectedRequest ||
                    progress ||
                    (request.commentHeading === true && comment === "")
                  }
                  size="large"
                  sx={{
                    pt: 1,
                    pb: 1,
                    fontSize: "1.2em",
                    ...(qrRequestColor && {
                      backgroundColor: qrRequestColor,
                    }),
                  }}
                >
                  {progress ? (
                    <CircularProgress />
                  ) : (
                    BasicLanguage.request.send[language]
                  )}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export const RequestErrorPage = () => {
  const language = useRecoilValue(languageState);
  const error = useRouteError();
  console.error(error);

  return (
    <Stack sx={{ m: 3, width: "100%" }} alignItems="center">
      <Alert severity="error" sx={{ width: "100%", maxWidth: "500px" }}>
        {BasicLanguage.request.noPage[language]}
      </Alert>
    </Stack>
  );
};

const RequestButton = (props) => {
  const label = props.label;
  const language = props.language;
  const selectedRequest = props.selectedRequest;
  const request = props.request;
  const [comment, setComment] = useRecoilState(commentState);
  const [image, setImage] = useRecoilState(imageState);

  const Input = styled("input")({
    display: "none",
  });

  const imageSelected = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const requestColor = props.backgroundColor;
  const backgroundColor = requestColor ? "#f5f5f5" : "rgba(20,132,201,0.1)";

  return (
    <Box
      sx={{
        pt: 1,
        pb: 1,
        backgroundColor: selectedRequest === label ? backgroundColor : "",
      }}
    >
      <Box>
        <FormControlLabel
          value={label}
          control={
            <Radio
              name={props.name}
              sx={{
                ...(requestColor && {
                  color: requestColor,
                  "&.Mui-checked": { color: requestColor },
                }),
              }}
            />
          }
          label={label}
          key={label}
          sx={{ pl: 3, pr: 0, m: 0, width: "100%" }}
        />
      </Box>
      {selectedRequest === label && (
        <Box sx={{ width: "100%", pt: 0, pb: 1.5 }}>
          <Stack>
            <Box sx={{ ml: 3, mr: 3 }}>
              <Typography
                sx={{ fontSize: "0.8em", mb: 1, textAlign: "center" }}
              >
                {BasicLanguage.request.uploadPicture[language]}
              </Typography>

              <label htmlFor="imageFileButton">
                <Input
                  id="imageFileButton"
                  type="file"
                  onChange={imageSelected}
                  accept="image/png, image/jpeg"
                />
                <Button
                  component="span"
                  variant="outlined"
                  fullWidth
                  sx={{
                    height: "3em",
                    borderRadius: "1.5em",
                    backgroundColor: Colors.white,
                    ...(requestColor && {
                      borderColor: "#999999",
                      color: "#999999",
                    }),
                  }}
                >
                  <AddCircleIcon
                    sx={{ mr: 1, ...(requestColor && { color: requestColor }) }}
                  />
                  {BasicLanguage.request.uploadPictureBtn[language]}
                </Button>
              </label>
              {image ? (
                <Box sx={{ p: 2 }}>
                  <img src={image} style={{ width: "100%" }} alt="uploaded" />
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Paper elevation={0} sx={{ mt: 3, mb: 0, ml: 3, mr: 3 }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                label={
                  request?.CommentPlaceHolder === "" ||
                  !request?.CommentPlaceHolder
                    ? BasicLanguage.request.commentField[language]
                    : request.CommentPlaceHolder
                }
                multiline
              ></TextField>
            </Paper>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

RequestButton.propTypes = {
  name: PropTypes.string,
  language: PropTypes.string,
  selectedRequest: PropTypes.string,
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
};

const isCleaningRobotAvailible = (robots, robotAreas, requestCleaningMode) => {
  if (!robots) {
    return false;
  }
  const now = dayjs();

  let todayOfWeek = dayjs().day() - 1;
  todayOfWeek = todayOfWeek === -1 ? 6 : todayOfWeek;

  for (let ids of robotAreas) {
    const area = robots[ids.robotId].floors[ids.floorId].areas[ids.areaId];
    const startHour = area.restrictedStartTime;
    const endHour = area.restrictedEndTime;
    const cleaningModes = robots[ids.robotId].cleaningModes;
    let weekFlag = false;

    if (cleaningModes[requestCleaningMode].includes(String(todayOfWeek))) {
      weekFlag = true;
    }

    if (startHour === null || endHour === null) {
      if (weekFlag) {
        return true;
      }
    }
    const startTime = dayjs().startOf("day").hour(startHour);
    const endTime = dayjs().startOf("day").hour(endHour);
    if (
      !(now.isSameOrAfter(startTime, "hour") && now.isBefore(endTime, "hour"))
    ) {
      if (weekFlag) {
        return true;
      }
    }
  }
  return false;
};
