import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import BasicLanguage from "../components/languages/Basic";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SBXStyles from "../components/utils/Styles";
import languageState from "../recoil/atoms/languageState";
import { useRecoilValue } from "recoil";

import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import Typography from "../components/parts-ui/Typography";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../components/utils/firebase";
import { getNameByUid } from "../components/utils/getUser";
import facilityState from "../recoil/atoms/facilityState";

import qrcode from "qrcode";
import CreateTask from "../components/parts/CreateTask";
import QrSensorRobotInfo from "../components/parts/forms/QrSensorRobotInfo";
import { useSurveyQuestions } from "../hooks/surveyQuestions";
import { SurveyQuestionEditForm } from "../components/parts/forms/SurveyQuestionEditForm";
import facilityCleaningModes from "../components/utils/facilityCleaningModes";
import { acceptInput, fileExtension } from "../components/utils/Conditions";
import { DialogYesNo } from "../components/parts/Dialog";
import TaskNotificationSwitch from "../components/parts/TaskNotificationSwitch";

const ENV = process.env.REACT_APP_FIRESTORE_ENV;
const VERSION = process.env.REACT_APP_FIRESTORE_VERSION;
const PHANTAS_CLEANING_MODES = ["清洗", "清扫", "尘推", "吸尘"];

const generateQR = async (url) => {
  try {
    return await qrcode.toDataURL(url, { width: 420 });
  } catch (err) {
    console.error(err);
  }
};

const QrDetail = () => {
  const facility = useRecoilValue(facilityState);
  const language = useRecoilValue(languageState);

  const [placeEditMode, setPlaceEditMode] = React.useState(false);
  const [newPlace, setNewPlace] = React.useState(0);
  const [requestsEditMode, setRequestsEditMode] = React.useState(false);
  const [newRequests, setNewRequests] = React.useState([]);
  const [assigneeEditMode, setAssigneeEditMode] = React.useState(false);
  const [newAssignee, setNewAssignee] = React.useState([]);
  const [active, setActive] = React.useState(true);
  const [displayRequest, setDisplayRequest] = React.useState(true);
  const [displaySurvey, setDisplaySurvey] = useState(false);
  const [activeSwitchDeactive, setActiveSwitchDeactive] = React.useState(false);
  const [requests, setRequests] = React.useState([]);

  const [completePageMessage, setCompletePageMessage] = useState("");
  const [completePageMessageEditMode, setCompletePageMessageEditMode] =
    React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [fetch, setFetch] = React.useState();
  const [qr, setQr] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [robots, setRobots] = React.useState([]);
  const [robotAreas, setRobotAreas] = React.useState([]);
  const [cleaningMode, setCleaningMode] = React.useState("");
  const [cleaningModeEditMode, setCleaningModeEditMode] = useState(false);
  const [selectedAreas, setSelectedAreas] = React.useState([]);

  const [robotAreasEditMode, setRobotAreasEditMode] = useState(false);
  const [areRobotAreasLoading, setAreRobotAreasLoading] = React.useState(true);
  const [selectedHeadings, setSelectedHeadings] = useState({
    QrSurveyTitleHeading: { enabled: false, content: "" },
    commentHeading: { enabled: false, value: false },
    CommentPlaceHolder: { enabled: false, content: "" },
  });
  const [notificationActive, setNotificationActive] = useState(false);
  const cleaningModes = facilityCleaningModes(robots);

  const params = useParams();
  const facilityId = params.facilityId;
  const qrId = params.qrId;
  const navigate = useNavigate();

  //
  // 場所の編集
  //
  const submitPlace = (e) => {
    e.preventDefault();

    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      place: newPlace,
    })
      .then(() => {
        getData();
        setPlaceEditMode(false);
      })
      .catch((e) => console.error(e));
  };
  const toPlaceEditMode = () => {
    setNewPlace(qr ? qr.place : "");
    setPlaceEditMode(true);
  };
  const cancelPlaceEdit = () => {
    setNewPlace("");
    setPlaceEditMode(false);
  };
  const changeNewPlace = (e) => {
    setNewPlace(e.target.value);
  };

  const updateHeadingValue = (key, newValue) => {
    console.log(key, "updating", newValue);
    setSelectedHeadings((prev) => ({
      ...prev,
      [key]:
        key === "commentHeading"
          ? { ...prev[key], value: !!newValue }
          : { ...prev[key], content: newValue },
    }));
  };

  const toggleHeadingEditMode = (key) => {
    setSelectedHeadings((prev) => ({
      ...prev,
      [key]: { ...prev[key], enabled: !prev[key].enabled },
    }));
  };

  //
  // リクエストの編集
  //
  const submitRequest = (e) => {
    e.preventDefault();
    setRequestsEditMode(false);
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      requestsList: newRequests,
    })
      .then(() => {
        getData();
        setRequestsEditMode(false);
      })
      .catch((e) => console.error(e));
  };
  const toRequestEditMode = () => {
    setNewRequests(qr ? qr.requestsList : []);
    setRequestsEditMode(true);
  };
  const cancelRequestsEdit = () => {
    setNewRequests([]);
    setRequestsEditMode(false);
  };

  const handleChangeRequests = (event) => {
    const {
      target: { value },
    } = event;
    setNewRequests(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //
  // 通知先の編集
  //

  const submitAssignee = (e) => {
    e.preventDefault();
    setAssigneeEditMode(false);

    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      assignee: newAssignee,
    })
      .then(() => {
        getData();
        setAssigneeEditMode(false);
      })
      .catch((e) => console.error(e));
  };
  const toAssigneeEditMode = () => {
    setNewAssignee(qr ? qr.assignee : []);
    setAssigneeEditMode(true);
  };
  const cancelAssigneeEdit = () => {
    setNewAssignee([]);
    setAssigneeEditMode(false);
  };

  const handleChangeAssignee = (event) => {
    const {
      target: { value },
    } = event;
    setNewAssignee(typeof value === "string" ? value.split(",") : value);
  };

  const submitTitles = async (e, key) => {
    e.preventDefault();

    // For commentHeading, it uses the boolean value
    const value =
      key === "commentHeading"
        ? selectedHeadings[key]?.value || false
        : selectedHeadings[key]?.content || "";

    const updatedData = { [key]: value };

    try {
      await updateDoc(
        doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId),
        updatedData
      );
      getData(); // Refresh after update
      // Exit edit mode
      setSelectedHeadings((prev) => ({
        ...prev,
        [key]: { ...prev[key], enabled: false },
      }));
    } catch (error) {
      console.error("Error updating document:", error);
      alert(BasicLanguage.alert.error.default[language]);
    }
  };

  /**
   * リクエストを表示
   */

  const handleDisplayRequestChange = (event) => {
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      hideRequest: !event.target.checked,
    })
      .then(() => {
        getData();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  /**
   * アンケートを表示
   */

  const handleDisplaySurveyChange = (event) => {
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      displaySurvey: event.target.checked,
    })
      .then(() => {
        getData();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  /**
   * 完了ページ文言
   */

  const toCompletePageEditMode = () => {
    if (qr && qr.completePageMessage) {
      setCompletePageMessage(qr.completePageMessage);
    }
    setCompletePageMessageEditMode(true);
  };

  const cancelCompletePageEdit = () => {
    setCompletePageMessage("");
    setCompletePageMessageEditMode(false);
  };

  const submitCompletePageMessage = (e) => {
    e.preventDefault();
    const qrDocRef = doc(
      db,
      ENV,
      VERSION,
      "facilities",
      facilityId,
      "qr",
      qrId
    );

    updateDoc(qrDocRef, {
      completePageMessage: completePageMessage,
    })
      .then(() => {
        cancelCompletePageEdit();
        getData();
      })
      .catch((e) => {
        console.error(e);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  //
  // 有効性の編集
  //
  const handleChangeActive = (e) => {
    setActiveSwitchDeactive(true);
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      active: e.target.checked,
    })
      .then(() => {
        getData();
        setActiveSwitchDeactive(false);
      })
      .catch((e) => {
        console.error(e);
        alert("変更できませんでした");
      });
  };

  //
  // クリーニングモード
  //
  const toCleaningModeEdit = () => {
    if (qr && qr.cleaningMode) {
      setCleaningMode(qr.cleaningMode);
    }
    setCleaningModeEditMode(true);
  };

  const submitCleaningMode = (e) => {
    e.preventDefault();
    updateDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId), {
      cleaningMode: cleaningMode,
    })
      .then(() => {
        getData();
        cancelCleaningModeEdit();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const cancelCleaningModeEdit = () => {
    setCleaningMode("");
    setCleaningModeEditMode(false);
  };

  //
  // ロボットエリア
  //
  const toRobotAreasEdit = () => {
    if (qr && qr.robotAreas) {
      setSelectedAreas(qr.robotAreas);
    }
    setRobotAreasEditMode(true);
  };

  const submitRobotAreas = async (e) => {
    e.preventDefault();

    const robotIds = selectedAreas.map((ids) => ids.robotId);
    if (new Set(robotIds).size !== robotIds.length) {
      alert(BasicLanguage.alert.oneRobotOnly[language]);
      return;
    }

    try {
      await runTransaction(db, async (transaction) => {
        const qrDocRef = doc(
          db,
          ENV,
          VERSION,
          "facilities",
          facilityId,
          "qr",
          qrId
        );

        const qrDoc = await transaction.get(qrDocRef);
        qrDoc.data().robotAreas?.forEach((robotArea) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              robotArea.robotId
            ),
            {
              [`floors.${robotArea.floorId}.areas.${robotArea.areaId}.qrIds`]:
                arrayRemove(qrId),
            },
            {
              merge: true,
            }
          );
        });

        transaction.update(qrDocRef, {
          robotAreas: selectedAreas.map((area) => {
            return {
              robotId: area.robotId,
              floorId: area.floorId,
              areaId: area.areaId,
            };
          }),
        });

        selectedAreas.forEach((area) => {
          transaction.update(
            doc(
              db,
              ENV,
              VERSION,
              "facilities",
              facilityId,
              "robots",
              area.robotId
            ),
            {
              [`floors.${area.floorId}.areas.${area.areaId}.qrIds`]:
                arrayUnion(qrId),
            },
            {
              merge: true,
            }
          );
        });
      });
      getData();
      cancelRobotAreasEdit();
    } catch (e) {
      console.error(e);
    }
  };

  const cancelRobotAreasEdit = () => {
    setSelectedAreas([]);
    setRobotAreasEditMode(false);
  };
  const updateNotificationSetting = async (newValue) => {
    try {
      const qrRef = doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId);
      await updateDoc(qrRef, { notifications: newValue });
    } catch (error) {
      console.error("エラー", error);
    }
  };

  const handleRobotAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAreas(typeof value === "string" ? value.split(",") : value);
  };

  const robotGroups = () => {
    if (robotAreasEditMode) {
      return selectedAreas.reduce((area, nextArea) => {
        (area[nextArea.robotId] = area[nextArea.robotId] || []).push(nextArea);
        return area;
      }, {});
    } else {
      return qr.robotAreas.reduce((area, nextArea) => {
        (area[nextArea.robotId] = area[nextArea.robotId] || []).push(nextArea);
        return area;
      }, {});
    }
  };

  const getDataUser = () => {
    getDoc(doc(db, ENV, VERSION, "facilities", facilityId))
      .then((x) => {
        const _users = []
          .concat(
            Object.keys(x.data().admin).map((y) => {
              return { ...x.data().admin[y], role: 0 };
            })
          )
          .concat(
            Object.keys(x.data().users).map((y) => {
              return { ...x.data().users[y], role: 1 };
            })
          );
        setUsers(_users);
      })
      .catch((e) => console.error(e));
  };

  const getUserData = async () => {
    const res = await getDoc(
      doc(db, ENV, VERSION, "facilities", facilityId)
    ).catch((e) => console.error(e));
    const data = res.data();
    return data;
  };

  const getDataTask = useCallback(() => {
    getDocs(
      query(collection(db, ENV, VERSION, "facilities", facilityId, "qrRequest"))
    )
      .then((querysnapshot) =>
        setRequests(
          querysnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
        )
      )
      .catch((e) => console.error(e));
  }, [facilityId]);

  const getData = async () => {
    const users = await getUserData();

    let adminAndUsers = Object.keys(users.users ? users.users : {})
      .map((uid) => users.users[uid])
      .concat(
        Object.keys(users.admin ? users.admin : {}).map(
          (uid) => users.admin[uid]
        )
      )
      .filter((user) => !user.isDeleted);

    setUsers(adminAndUsers);

    getDoc(doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId))
      .then(async (data) => {
        const host = process.env.REACT_APP_HOST || window.location.origin;
        const url = host + "/request/" + data.id;
        const qr = {
          ...data.data(),
          url: url,
          qr: host ? await generateQR(url) : undefined,
          id: data.id,
          //削除されてしまったユーザーを除外する
          assignee: data
            .data()
            .assignee.filter(
              (assignee) =>
                adminAndUsers.map((user) => user.uid).indexOf(assignee) > -1
            ),
          ...(robots.length !== 0 && {
            cleaningMode: data.data().cleaningMode,
            robotAreas: data
              .data()
              .robotAreas?.map((area) => {
                return robotAreas.find((robotArea) => {
                  return (
                    area.robotId === robotArea.robotId &&
                    area.floorId === robotArea.floorId &&
                    area.areaId === robotArea.areaId
                  );
                });
              })
              .filter((robotArea) => !!robotArea),
          }),
        };
        setSelectedHeadings((prev) => ({
          ...prev,
          QrSurveyTitleHeading: {
            ...prev.QrSurveyTitleHeading,
            content: qr.QrSurveyTitleHeading || "",
          },
          commentHeading: {
            ...prev.commentHeading,
            value: Boolean(qr.commentHeading), // Ensure it's a boolean
          },
          CommentPlaceHolder: {
            ...prev.CommentPlaceHolder,
            content: qr.CommentPlaceHolder || "",
          },
        }));
        setQr(qr);
        setNotificationActive(qr.notifications);
        setActive(qr.active);
        setDisplayRequest(!qr.hideRequest);
        setDisplaySurvey(!!qr.displaySurvey);
      })
      .catch((e) => console.error(e));
  };

  const handleDownload = () => {
    const url = window.URL.createObjectURL(toBlob(qr.qr, acceptInput));
    const a = document.createElement("a");
    a.href = url;
    a.download = facility.name + "-" + qr.place + fileExtension;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  function toBlob(base64, mime_ctype) {
    // 日本語の文字化けに対処するためBOMを作成する。
    var bom = new Uint8Array([0xef, 0xbb, 0xbf]);

    var bin = atob(base64.replace(/^.*,/, ""));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    try {
      var blob = new Blob([buffer.buffer], {
        type: mime_ctype,
      });
    } catch (e) {
      return false;
    }
    return blob;
  }

  const getRobotAreas = React.useCallback(() => {
    getDocs(
      query(
        collection(db, ENV, VERSION, "facilities", facilityId, "robots"),
        where("active", "==", true)
      )
    )
      .then((docsnap) => {
        if (docsnap.empty) {
          return;
        }
        setRobots(
          docsnap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        let robotFloorAreas = [];
        docsnap.docs.forEach((doc) => {
          const floors = doc.data().floors;
          if (!floors) {
            return;
          }
          const robotId = doc.id;
          const robotName = doc.data().name;
          const floorIds = Object.keys(floors);
          floorIds.forEach((floorId) => {
            const areas = floors[floorId].areas;
            if (!areas) {
              return;
            }
            Object.keys(areas).forEach((areaId) => {
              const areaObject = {
                robotId: robotId,
                robotName: robotName,
                floorId: floorId,
                areaId: areaId,
                areaName: areas[areaId].name,
              };

              robotFloorAreas = [...robotFloorAreas, areaObject];
            });
          });
        });
        setRobotAreas(robotFloorAreas);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setAreRobotAreasLoading(false);
      });
  }, [facilityId]);

  React.useEffect(() => {
    getDataTask();
  }, [getDataTask, fetch]);

  React.useEffect(() => {
    getRobotAreas();
    getDataUser();
  }, [getRobotAreas]);

  React.useEffect(() => {
    if (!areRobotAreasLoading) {
      getData();
    }
  }, [areRobotAreasLoading]);

  const handleDelete = async () => {
    setDeleteModalOpen(false);
    const qrRef = doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId);

    try {
      await updateDoc(qrRef, { displayDisable: true, active: false });
      alert(BasicLanguage.qr.displayState[language]);
      navigate("../qr");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box component="main" sx={SBXStyles.mainContainer}>
      <Typography className="pageTitle" variant="h4">
        <IconButton LinkComponent={Link} to={"/" + params.facilityId + "/qr"}>
          <ArrowBackIcon />
        </IconButton>
        {BasicLanguage.common.menu.qr[language]}
      </Typography>
      <Box sx={SBXStyles.mainBox}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{qr?.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{BasicLanguage.qr.detail.place[language]}</TableCell>

              <TableCell>
                {placeEditMode ? (
                  <form onSubmit={submitPlace}>
                    <TextField
                      variant="standard"
                      onChange={changeNewPlace}
                      defaultValue={qr.place}
                      autoFocus={true}
                    />
                    <IconButton type="submit">
                      <CheckCircleIcon color="success" />
                    </IconButton>
                    <IconButton onClick={cancelPlaceEdit}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </form>
                ) : (
                  <>
                    {qr?.place}
                    <IconButton sx={{ ml: 1 }} onClick={toPlaceEditMode}>
                      <EditIcon sx={{ fontSize: "0.7em" }} />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
            {/* みだしリクエストタイトル */}
            {Object.keys(selectedHeadings).map((key) => (
              <TableRow sx={{ flexGrow: "2" }} key={key}>
                <TableCell>
                  {BasicLanguage.qr.qrResquestTitle?.[key]?.[language] ??
                    `Missing: ${key}`}
                </TableCell>
                <TableCell>
                  {key === "commentHeading" ? (
                    // Switch for commentHeading
                    <Switch
                      checked={selectedHeadings.commentHeading.value}
                      onChange={async (e) => {
                        const newValue = e.target.checked;
                        updateHeadingValue("commentHeading", newValue);
                        // Pass the new value directly:
                        await updateDoc(
                          doc(
                            db,
                            ENV,
                            VERSION,
                            "facilities",
                            facilityId,
                            "qr",
                            qrId
                          ),
                          {
                            commentHeading: newValue,
                          }
                        );
                      }}
                    />
                  ) : selectedHeadings[key].enabled ? (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        submitTitles(e, key);
                      }}
                    >
                      <TextField
                        variant="standard"
                        onChange={(e) =>
                          updateHeadingValue(key, e.target.value)
                        }
                        value={selectedHeadings[key].content}
                        autoFocus
                      />
                      <IconButton type="submit">
                        <CheckCircleIcon color="success" />
                      </IconButton>
                      <IconButton onClick={() => toggleHeadingEditMode(key)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </form>
                  ) : (
                    <>
                      {selectedHeadings[key].content ||
                        BasicLanguage.request.noRequestTitles[language]}
                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => toggleHeadingEditMode(key)}
                      >
                        <EditIcon sx={{ fontSize: "0.7em" }} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>{BasicLanguage.qr.detail.request[language]}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {requestsEditMode ? (
                    <Stack direction="row" spacing={1}>
                      <Select
                        sx={{ flexGrow: 1 }}
                        name="requestsList"
                        value={newRequests}
                        multiple
                        onChange={handleChangeRequests}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {requests.map((x) => (
                          <MenuItem key={x.name} value={x.name}>
                            {x.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <CreateTask
                        taskType="qrRequest"
                        existingTasks={[requests]}
                        disabled={true}
                        fetch={setFetch}
                      />
                      <IconButton
                        onClick={submitRequest}
                        disabled={
                          newRequests.length === 0 &&
                          (!qr?.cleaningMode || qr?.robotAreas?.length === 0)
                        }
                        sx={{ "&[disabled]": { opacity: 0.3 } }}
                      >
                        <CheckCircleIcon color="success" />
                      </IconButton>
                      <IconButton onClick={cancelRequestsEdit}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Stack>
                  ) : (
                    <>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {qr?.requestsList.map((x) => (
                          <Chip key={x} label={x} />
                        ))}
                      </Box>
                      <IconButton sx={{ ml: 1 }} onClick={toRequestEditMode}>
                        <EditIcon sx={{ fontSize: "0.7em" }} />
                      </IconButton>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.qr.detail.assignee[language]}
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {assigneeEditMode ? (
                    <>
                      <FormControl fullWidth error={newAssignee.length === 0}>
                        <Select
                          sx={{ flexGrow: 1 }}
                          name="assignee"
                          value={newAssignee}
                          multiple
                          onChange={handleChangeAssignee}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={getNameByUid(facility, value)}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {users.map((x) => (
                            <MenuItem key={x.uid} value={x.uid}>
                              {x.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                        {newAssignee.length === 0 && (
                          <FormHelperText>
                            {BasicLanguage.qr.detail.noAssignee[language]}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <IconButton
                        onClick={submitAssignee}
                        disabled={newAssignee.length === 0}
                        sx={{ "&[disabled]": { opacity: 0.3 } }}
                      >
                        <CheckCircleIcon color="success" />
                      </IconButton>
                      <IconButton onClick={cancelAssigneeEdit}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {qr?.assignee?.length === 0 ? (
                          <Typography color="error">
                            {BasicLanguage.qr.detail.noAssignee[language]}
                          </Typography>
                        ) : (
                          qr?.assignee.map((user) => (
                            <Chip
                              key={user}
                              label={getNameByUid(facility, user)}
                            />
                          ))
                        )}
                      </Box>
                      <IconButton sx={{ ml: 1 }} onClick={toAssigneeEditMode}>
                        <EditIcon sx={{ fontSize: "0.7em" }} />
                      </IconButton>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.qr.detail.displayRequest[language]}
              </TableCell>
              <TableCell>
                <Switch
                  checked={displayRequest}
                  onChange={handleDisplayRequestChange}
                  disabled={displayRequest && !displaySurvey}
                />
                {!displayRequest && !displaySurvey && (
                  <Alert severity="warning">
                    {BasicLanguage.qr.detail.displayWarning[language]}
                  </Alert>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {BasicLanguage.qr.detail.displaySurvey[language]}
              </TableCell>
              <TableCell>
                <Switch
                  checked={displaySurvey}
                  onChange={handleDisplaySurveyChange}
                  disabled={displaySurvey && !displayRequest}
                />
                {!displayRequest && !displaySurvey && (
                  <Alert severity="warning">
                    {BasicLanguage.qr.detail.displayWarning[language]}
                  </Alert>
                )}
              </TableCell>
            </TableRow>
            {displaySurvey === true && (
              <SurveyQuestionsRow
                language={language}
                facilityId={facilityId}
                qrId={qrId}
                disabledSurveyQuestions={qr?.disabledSurveyQuestions || []}
                refetcher={getData}
              />
            )}
            <TableRow>
              <TableCell>
                {BasicLanguage.qr.detail.completePageMessage[language]}
              </TableCell>
              <TableCell>
                {completePageMessageEditMode ? (
                  <form onSubmit={submitCompletePageMessage}>
                    <TextField
                      variant="standard"
                      onChange={(e) => setCompletePageMessage(e.target.value)}
                      defaultValue={completePageMessage}
                      autoFocus={true}
                      multiline
                    />
                    <IconButton type="submit">
                      <CheckCircleIcon color="success" />
                    </IconButton>
                    <IconButton onClick={cancelCompletePageEdit}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </form>
                ) : (
                  <Box sx={{ whiteSpace: "pre-wrap" }}>
                    {qr?.completePageMessage}
                    <IconButton sx={{ ml: 1 }} onClick={toCompletePageEditMode}>
                      <EditIcon sx={{ fontSize: "0.7em" }} />
                    </IconButton>
                  </Box>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {
                  BasicLanguage.whatsAppRegisteration.completeTaskNotif[
                    language
                  ]
                }
              </TableCell>
              <TableCell>
                <TaskNotificationSwitch
                  active={notificationActive}
                  setActive={(newValue) => {
                    setNotificationActive(newValue);
                    updateNotificationSetting(newValue);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{BasicLanguage.common.active[language]}</TableCell>
              <TableCell>
                <Switch
                  checked={active}
                  onChange={handleChangeActive}
                  disabled={activeSwitchDeactive}
                />
                {active === false && (
                  <Alert severity="warning">
                    {BasicLanguage.qr.detail.warning[language]}
                  </Alert>
                )}
              </TableCell>
            </TableRow>

            {robots.length !== 0 && (
              <>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.qr.detail.cleaningMode[language]}
                  </TableCell>
                  <TableCell>
                    {cleaningModeEditMode ? (
                      <Box sx={{ display: "flex" }}>
                        <FormControl
                          fullWidth
                          error={!["", ...cleaningModes].includes(cleaningMode)}
                        >
                          <Select
                            fullWidth
                            name="cleaningMode"
                            value={cleaningMode}
                            onChange={(e) => setCleaningMode(e.target.value)}
                            error={
                              !["", ...cleaningModes].includes(cleaningMode)
                            }
                          >
                            <MenuItem sx={{ height: 30 }} value={""}></MenuItem>
                            {[...cleaningModes].map((mode) => {
                              return (
                                <MenuItem key={mode} value={mode}>
                                  {PHANTAS_CLEANING_MODES.includes(mode)
                                    ? BasicLanguage.robots.detail.cleaningModes
                                        .mode[mode][language]
                                    : mode}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {!["", ...cleaningModes].includes(cleaningMode) && (
                            <FormHelperText>
                              {
                                BasicLanguage.robots.detail.cleaningModes
                                  .cleaningModeDeleted[language]
                              }
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Box display={"flex"} alignContent={"center"}>
                          <IconButton
                            onClick={submitCleaningMode}
                            disabled={
                              (qr?.requestsList?.length === 0 &&
                                !cleaningMode) ||
                              !["", ...cleaningModes].includes(cleaningMode)
                            }
                            sx={{ "&[disabled]": { opacity: 0.3 } }}
                          >
                            <CheckCircleIcon color="success" />
                          </IconButton>
                          <IconButton onClick={cancelCleaningModeEdit}>
                            <CancelIcon color="error" />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Box sx={{ display: "flex" }}>
                          <Box>
                            <Typography
                              color={
                                !["", ...cleaningModes].includes(
                                  qr?.cleaningMode
                                )
                                  ? "error"
                                  : ""
                              }
                            >
                              {qr?.cleaningMode &&
                                (PHANTAS_CLEANING_MODES.includes(
                                  qr.cleaningMode
                                )
                                  ? BasicLanguage.robots.detail.cleaningModes
                                      .mode[qr.cleaningMode][language]
                                  : qr.cleaningMode)}
                            </Typography>
                          </Box>
                          <IconButton
                            sx={{ ml: 1 }}
                            onClick={toCleaningModeEdit}
                          >
                            <EditIcon sx={{ fontSize: "0.7em" }} />
                          </IconButton>
                        </Box>
                        {qr?.cleaningMode &&
                          !["", ...cleaningModes].includes(qr.cleaningMode) && (
                            <Typography
                              color="error"
                              sx={{ fontSize: ".75rem" }}
                            >
                              {
                                BasicLanguage.robots.detail.cleaningModes
                                  .cleaningModeDeleted[language]
                              }
                            </Typography>
                          )}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {BasicLanguage.qr.detail.robotAreas[language]}
                  </TableCell>
                  <TableCell>
                    {robotAreasEditMode ? (
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ flex: 1 }}>
                          <FormControl fullWidth>
                            <Select
                              name="robotAreas"
                              value={selectedAreas}
                              multiple
                              onChange={handleRobotAreaChange}
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={`${value.robotId}_${value.floorId}_${value.areaId}`}
                                      label={`${value?.areaName} (${
                                        value.robotName ||
                                        BasicLanguage.robots.undefined[language]
                                      })`}
                                    />
                                  ))}
                                </Box>
                              )}
                            >
                              {robotAreas.map((robotArea) => (
                                <MenuItem
                                  key={`${robotArea.robotId}_${robotArea.floorId}_${robotArea.areaId}`}
                                  value={robotArea}
                                >
                                  {`${robotArea.areaName} (${
                                    robotArea.robotName ||
                                    BasicLanguage.robots.undefined[language]
                                  })`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignContent: "center" }}>
                          <IconButton
                            onClick={submitRobotAreas}
                            disabled={
                              qr?.requestsList?.length === 0 &&
                              selectedAreas.length === 0
                            }
                            sx={{ "&[disabled]": { opacity: 0.3 } }}
                          >
                            <CheckCircleIcon color="success" />
                          </IconButton>
                          <IconButton onClick={cancelRobotAreasEdit}>
                            <CancelIcon color="error" />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {qr?.robotAreas?.map((area) => (
                            <Chip
                              key={area}
                              label={`${area.areaName} (${
                                robots.find(
                                  (robot) => robot.id === area.robotId
                                ).name ||
                                BasicLanguage.robots.undefined[language]
                              })`}
                            />
                          ))}
                        </Box>
                        <IconButton sx={{ ml: 1 }} onClick={toRobotAreasEdit}>
                          <EditIcon sx={{ fontSize: "0.7em" }} />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow>
              <TableCell>
                {BasicLanguage.qr.detail.requestPage[language]}
              </TableCell>
              <TableCell>
                {qr?.url ? (
                  <Link to={qr.url} target="_blank">
                    {qr.url}
                  </Link>
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button
          variant="contained"
          color="error"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => setDeleteModalOpen(true)}
        >
          {BasicLanguage.common.delete[language]}
        </Button>
      </Box>
      {robots.length !== 0 && (
        <Box sx={{ ...SBXStyles.mainBox }}>
          <Typography variant="h5">
            {BasicLanguage.qr.robotAreaInfo[language]}
          </Typography>
          {(robotAreasEditMode || qr?.robotAreas) && (
            <QrSensorRobotInfo
              robots={robots}
              robotGroups={robotGroups()}
              robotAreas={robotAreasEditMode ? selectedAreas : qr?.robotAreas}
            />
          )}
        </Box>
      )}
      <Box sx={{ ...SBXStyles.mainBox }}>
        {qr?.qr && (
          <>
            <Typography variant="h5">
              {BasicLanguage.qr.qr[language]}
            </Typography>
            <Stack>
              <Box sx={{ textAlign: "center" }}>
                <img src={qr?.qr} alt="2D Barcode" />
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button onClick={handleDownload} variant="outlined">
                  {BasicLanguage.qr.detail.saveButton[language]}
                </Button>
              </Box>
            </Stack>
          </>
        )}
        <DialogYesNo
          open={[deleteModalOpen, setDeleteModalOpen]}
          yesAction={handleDelete}
          noAction={() => setDeleteModalOpen(false)}
          title={BasicLanguage.qr.dialog.delete.title[language]}
          message={BasicLanguage.qr.dialog.delete.message[language]}
        />
      </Box>
    </Box>
  );
};

const SurveyQuestionsRow = ({
  language,
  facilityId,
  qrId,
  disabledSurveyQuestions,
  refetcher,
}) => {
  const surveyQuestions = useSurveyQuestions(facilityId);
  const warnUser = disabledSurveyQuestions?.length >= surveyQuestions?.length;
  const warningText = BasicLanguage.qr.survey.warning[language];
  return (
    <TableRow>
      <TableCell>{BasicLanguage.qr.detail.surveyQuestions[language]}</TableCell>
      <TableCell>
        <FormControl error={warnUser}>
          <FormHelperText>{warnUser ? warningText : ""}</FormHelperText>
        </FormControl>
        <List>
          {surveyQuestions.map((surveyQuestion) => {
            const isEnabled = !disabledSurveyQuestions.includes(
              surveyQuestion.id
            );
            return (
              <ListItem key={surveyQuestion.id}>
                <Switch
                  checked={isEnabled}
                  onClick={async (event) => {
                    event.preventDefault();
                    await updateDisabledSurveyQuestions(
                      facilityId,
                      qrId,
                      surveyQuestion.id,
                      isEnabled ? "arrayUnion" : "arrayRemove"
                    );
                    await refetcher();
                  }}
                />
                <SurveyQuestionEditForm
                  facilityId={facilityId}
                  surveyQuestion={surveyQuestion}
                />
              </ListItem>
            );
          })}
        </List>
      </TableCell>
    </TableRow>
  );
};

const updateDisabledSurveyQuestions = async (
  facilityId,
  qrId,
  surveyQuestionId,
  intent
) => {
  const docRef = doc(db, ENV, VERSION, "facilities", facilityId, "qr", qrId);
  switch (intent) {
    case "arrayUnion":
      await updateDoc(docRef, {
        disabledSurveyQuestions: arrayUnion(surveyQuestionId),
      });
      break;
    case "arrayRemove":
      await updateDoc(docRef, {
        disabledSurveyQuestions: arrayRemove(surveyQuestionId),
      });
      break;
    default:
      throw new Error("Invalid intent");
  }
};

export default QrDetail;
