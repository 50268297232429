import { useEffect, useState } from "react";
import {
  requestGetSurvey,
  requestSendSurvey,
} from "../components/utils/functions";
import { useLoaderData, useNavigate, useParams } from "react-router";
import {
  Box,
  Button,
  CircularProgress,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import RequestLayout from "../components/parts/request/RequestLayout";
import { useRecoilValue } from "recoil";
import languageState from "../recoil/atoms/languageState";
import BasicLanguage from "../components/languages/Basic";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

const RATING_DEFAULT = 0;

export default function RequestPageSurvery() {
  const { isRedirect, logo, surveyQuestions, facility, place, qrRequestColor } =
    useLoaderData();
  const { control, handleSubmit } = useForm();
  const params = useParams();
  const qrId = params.qrId;
  const navigate = useNavigate();

  const language = useRecoilValue(languageState);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (isRedirect) {
      return navigate(`../${qrId}/thanks`);
    }
  }, [isRedirect, navigate, qrId]);

  const submitSurvey = (data) => {
    setIsSending(true);
    requestSendSurvey({ qrId: qrId, ratings: data })
      .then((result) => {
        const { status, reason } = result.data;
        if (status === "success") {
          return navigate(`../${qrId}/thanks`);
        } else {
          throw new Error(reason);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsSending(false);
        alert(BasicLanguage.alert.error.default[language]);
      });
  };

  if (!isRedirect) {
    return (
      <RequestLayout
        logo={logo}
        facility={facility}
        place={place}
        qrRequestColor={qrRequestColor}
      >
        <Box>
          <Typography sx={{ textAlign: "center", mt: 3, mb: 3, px: 3 }}>
            {BasicLanguage.request.survey.pleaseAnswer[language]}
          </Typography>
          <form onSubmit={handleSubmit((data) => submitSurvey(data))}>
            {surveyQuestions.map((question) => (
              <SurveyQuestion
                key={question.id}
                question={question.question}
                control={control}
              />
            ))}
            <Stack spacing={2} sx={{ mx: 3, mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  pt: 1,
                  pb: 1,
                  fontSize: "1.2em",
                  ...(qrRequestColor && { backgroundColor: qrRequestColor }),
                }}
                disabled={isSending}
              >
                {isSending ? (
                  <CircularProgress />
                ) : (
                  BasicLanguage.request.send[language]
                )}
              </Button>
            </Stack>
          </form>
        </Box>
      </RequestLayout>
    );
  }
}

const SurveyQuestion = ({ question, control }) => {
  return (
    <Box sx={{ textAlign: "center", mt: 3, mb: 3 }}>
      <Typography component="legend">{question}</Typography>
      <Controller
        name={question}
        control={control}
        defaultValue={RATING_DEFAULT}
        render={({ field }) => (
          <Rating
            defaultValue={RATING_DEFAULT}
            onChange={(e) => field.onChange(parseInt(e.target.value))}
            size="large"
            value={field.value}
          />
        )}
      />
    </Box>
  );
};

SurveyQuestion.propTypes = {
  question: PropTypes.string,
  control: PropTypes.object,
};

export const loadRequestSurvey = async ({ params }) => {
  const { qrId } = params;

  return await requestGetSurvey({ id: qrId }).then((result) => {
    const { status, content } = result.data;
    if (status === "success") {
      return { isRedirect: false, ...content };
    } else if (status === "redirect") {
      return { isRedirect: true };
    }

    throw new Error("Data not found");
  });
};
