import { Box, Switch } from "@mui/material";
import * as React from "react";

const TaskNotificationSwitch = ({ active, setActive }) => {
  return (
    <Switch onChange={(e) => setActive(e.target.checked)} checked={active} />
  );
};

export default TaskNotificationSwitch;
