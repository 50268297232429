export const getWeekNumber = (date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const isLeapYear = (year) =>
    year % 400 === 0 ? true : year % 100 === 0 ? false : year % 4 === 0;

  const days = Math.ceil((date - startOfYear) / (24 * 60 * 60 * 1000));
  const year = date.getFullYear();

  return isLeapYear(year)
    ? Math.ceil((days + startOfYear.getDay()) / 7)
    : Math.floor((days + startOfYear.getDay()) / 7);
};
