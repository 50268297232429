import moment from "moment";
import React from "react";
import BasicLanguage from "../../../languages/Basic";
import { Box } from "@mui/material";
import { SbxStackedBarChart } from "../../SbxChart";
import { useRecoilValue } from "recoil";
import languageState from "../../../../recoil/atoms/languageState";
import PropTypes from "prop-types";
import { getWeekNumber } from "../../../utils/GetWeeks";

export default function RequestChart({ requestData, date, dateDisplay }) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    requestData = generateDataByDay(requestData, language, date);
  } else if (dateDisplay === "week") {
    requestData = generateDataByWeek(requestData, language, date);
  } else if (dateDisplay === "month") {
    requestData = generateDataByMonth(requestData, language, date);
  }

  return (
    <Box sx={{ width: "100%", height: "300px" }}>
      <SbxStackedBarChart
        data={requestData}
        bottomDataKey={
          BasicLanguage.dashboard.kpi.request.completedRequests[language]
        }
        topDataKey={
          BasicLanguage.dashboard.kpi.request.incompleteRequests[language]
        }
      />
    </Box>
  );
}

RequestChart.propTypes = {
  requestData: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
};

const generateDataByMonth = (tasks, language, date) => {
  const qrTasks = tasks.reduce(
    (tasks, task) => {
      const completed = !!task.completed;
      if (completed) {
        tasks.month.complete++;
      } else {
        tasks.month.incomplete++;
      }
      return tasks;
    },
    { month: { incomplete: 0, complete: 0 } }
  );

  return Object.keys(qrTasks).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.request.month[language]
      ),
      [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
        qrTasks[key].complete,
      [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
        qrTasks[key].incomplete,
    };
  });
};

const generateDataByWeek = (tasks, language, date) => {
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByWeek:", date);
      throw new Error("Invalid date provided to generateDataByWeek");
    }
  }

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const firstWeek = getWeekNumber(startOfMonth);
  const lastWeek = getWeekNumber(endOfMonth);

  const defaultWeeks = {};
  for (let i = firstWeek; i <= lastWeek; i++) {
    defaultWeeks[i === 0 ? 1 : i] = {
      complete: 0,
      incomplete: 0,
    };
  }

  tasks.forEach((task) => {
    if (!task.createdAt) {
      console.error("Invalid createdAt:", task);
      return;
    }
    const taskDate = new Date(task.createdAt);
    if (isNaN(taskDate.getTime())) {
      console.error("Invalid task date:", task);
      return;
    }

    const taskWeek = getWeekNumber(taskDate);
    const completed = !!task.completed;

    if (!defaultWeeks[taskWeek]) {
      defaultWeeks[taskWeek] = {
        complete: 0,
        incomplete: 0,
      };
    }

    if (completed) {
      defaultWeeks[taskWeek].complete += 1;
    } else {
      defaultWeeks[taskWeek].incomplete += 1;
    }
  });

  const mappedWeeks = Object.keys(defaultWeeks)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((week) => {
      const weekData = defaultWeeks[week];
      return {
        name: `${week}`,
        [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
          weekData.complete,
        [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
          weekData.incomplete,
      };
    });

  return mappedWeeks;
};

const generateDataByDay = (tasks, language, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = { complete: 0, incomplete: 0 };
  }
  const qrTasks = tasks.reduce((tasks, task) => {
    const taskDate = moment(task.createdAt).date();
    const completed = !!task.completed;
    if (completed) {
      tasks[taskDate] = {
        ...tasks[taskDate],
        complete: (tasks[taskDate]?.complete || 0) + 1,
      };
    } else {
      tasks[taskDate] = {
        ...tasks[taskDate],
        incomplete: (tasks[taskDate]?.incomplete || 0) + 1,
      };
    }
    return tasks;
  }, defaultDays);

  return Object.keys(qrTasks)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.request.completedRequests[language]]:
          qrTasks[key].complete,
        [BasicLanguage.dashboard.kpi.request.incompleteRequests[language]]:
          qrTasks[key].incomplete,
      };
    });
};
