import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

//********** 管理者確認 **********
export const isAdmin = httpsCallable(functions, "api/v1/admin/check");

//********** ユーザー **********
export const adminUserAdd = httpsCallable(functions, "api/v1/admin/user/add");
export const adminUserAddAdmin = httpsCallable(
  functions,
  "api/v1/admin/user/add_admin"
);
export const updateUserNameAndEmail = httpsCallable(
  functions,
  "api/v1/admin/user/update_user_name_and_email"
);
export const deleteAuthUser = httpsCallable(
  functions,
  "api/v1/admin/user/delete_auth_user"
);
export const getUserListAsAdmin = httpsCallable(
  functions,
  "api/v1/admin/user/list"
);
export const adminUserRemoveAdmin = httpsCallable(
  functions,
  "api/v1/admin/user/remove_admin"
);
export const userUpdate = httpsCallable(functions, "api/v1/user/update");
export const userUpdateNumber = httpsCallable(
  functions,
  "api/v1/user/update_number"
);

export const userVerificationEmail = httpsCallable(
  functions,
  "api/v1/user/email_verification"
);
export const userUpdateEmail = httpsCallable(
  functions,
  "api/v1/user/update_email"
);

//********** 施設 **********
export const getFacilityList = httpsCallable(
  functions,
  "api/v1/admin/facility/list"
);
export const adminFacilityAdd = httpsCallable(
  functions,
  "api/v1/admin/facility/add"
);
export const adminFacilityUpdate = httpsCallable(
  functions,
  "api/v1/admin/facility/update"
);
export const adminFacilityDelete = httpsCallable(
  functions,
  "api/v1/admin/facility/delete"
);
export const getFacilities = httpsCallable(
  functions,
  "api/v1/user/get_facilities"
);
export const checkUserToFacility = httpsCallable(
  functions,
  "api/v1/facility/user/check"
);

//********** センサー **********
export const getSensorsList = httpsCallable(
  functions,
  "api/v1/admin/sensors/list"
);
export const adminSensorsAdd = httpsCallable(
  functions,
  "api/v1/admin/sensors/add"
);
export const adminSensorsUpdate = httpsCallable(
  functions,
  "api/v1/admin/sensors/update"
);
export const adminSensorsDelete = httpsCallable(
  functions,
  "api/v1/admin/sensors/delete"
);

//********** ロボット **********
export const getRobotsList = httpsCallable(
  functions,
  "api/v1/admin/robots/list"
);
export const adminRobotsAdd = httpsCallable(
  functions,
  "api/v1/admin/robots/add"
);
export const adminRobotsUpdate = httpsCallable(
  functions,
  "api/v1/admin/robots/update"
);
export const adminRobotsDelete = httpsCallable(
  functions,
  "api/v1/admin/robots/delete"
);

//********** タスク完了通知メール **********
export const notifyTaskCompletion = httpsCallable(
  functions,
  "api/v1/admin/request/send_email_task_complete"
);
export const whatsAppNotify = httpsCallable(
  functions,
  "api/v1/admin/request/whats_app_notify"
);
//********** KPI **********
export const adminKpiAdd = httpsCallable(functions, "api/v1/admin/kpi/add");
export const adminKpiList = httpsCallable(functions, "api/v1/admin/kpi/list");
export const adminKpiUpdate = httpsCallable(
  functions,
  "api/v1/admin/kpi/update"
);
export const adminKpiDelete = httpsCallable(
  functions,
  "api/v1/admin/kpi/delete"
);

//********** リクエスト **********
export const requestGet = httpsCallable(functions, "api/v1/request/get");
export const requestGetRobots = httpsCallable(
  functions,
  "api/v1/request/get_robots"
);
export const requestGetSurvey = httpsCallable(
  functions,
  "api/v1/request/get_survey"
);
export const requestSend = httpsCallable(functions, "api/v1/request/send");
export const requestSendSurvey = httpsCallable(
  functions,
  "api/v1/request/send_survey"
);
export const requestGetMessage = httpsCallable(
  functions,
  "api/v1/request/get_message"
);
export const robotStart = httpsCallable(
  functions,
  "api/v1/request/start_robot"
);
export const robotStartSend = httpsCallable(
  functions,
  "api/v1/request/robot_start_send"
);

//********** Gaussian API **********
export const getV1RobotStatus = httpsCallable(
  functions,
  "api/v1/gaussian/get_v1_status"
);

export const getReportImage = httpsCallable(
  functions,
  "api/v1/gaussian/get_report_image"
);

//********** 画像 **********
export const imageGet = httpsCallable(functions, "api/v1/image/get");
