const Basic = {
  common: {
    role: {
      0: {
        ja: "管理者",
        en: "Admin",
      },
      1: {
        ja: "ユーザー",
        en: "User",
      },
    },

    menu: {
      home: {
        ja: "ホーム",
        en: "Home",
      },

      timezone: {
        ja: "施設設定を管理する",
        en: "Facility Settings",
      },

      dashboard: {
        ja: "ダッシュボード",
        en: "Dashboard",
      },
      facility: {
        ja: "施設情報",
        en: "Facility",
      },
      user: {
        ja: "ユーザー",
        en: "Users",
      },
      setting: {
        ja: "施設設定",
        en: "Facility settings",
      },
      sensor: {
        ja: "センサー",
        en: "Sensors",
      },
      qr: {
        ja: "二次元バーコード",
        en: "2D barcode",
      },
      atp: {
        ja: "ATP",
        en: "ATP",
      },
      report: {
        ja: "清掃レポート",
        en: "Reports",
      },
      maps: {
        ja: "マップ",
        en: "Maps",
      },
      robots: {
        ja: "ロボット",
        en: "Robots",
      },
    },
    modal: {
      yes: {
        ja: "はい",
        en: "Yes",
      },
      no: {
        ja: "いいえ",
        en: "No",
      },
    },
    date: {
      datePicker: {
        long: {
          ja: "YYYY年MM月DD日",
          en: "YYYY/MM/DD",
        },
        monthAndYear: {
          ja: "YYYY年MM月",
          en: "MM YYYY",
        },
        yearAndMonth: {
          ja: "YYYY年MM月",
          en: "YYYY/M",
        },
        cancel: {
          ja: "キャンセル",
          en: "Cancel",
        },

        DataError: {
          ja: "データの取得エラー",
          en: "Can't fetch any Data.",
        },

        noSensorDataFound: {
          ja: "選択されたセンサーのデータがありません。",
          en: "No data for the selected Sensor was found. ",
        },
      },
      month: {
        ja: "月",
        en: "Month",
      },
      week: {
        ja: "週",
        en: "Week",
      },
      day: {
        ja: "日",
        en: "Day",
      },
      startDate: {
        ja: "開始日",
        en: "Start Date",
      },
      endDate: {
        ja: "終了日",
        en: "End Date",
      },
      outOfRange: {
        ja: "日付が範囲外です。",
        en: "Date is out of range.",
      },
      invalidDate: {
        ja: "無効な日付です。",
        en: "Invalid date.",
      },
    },
    delete: {
      ja: "削除",
      en: "Delete",
    },
    update: {
      ja: "更新",
      en: "Update",
    },
    active: {
      ja: "有効",
      en: "Active",
    },
    inactive: {
      ja: "無効",
      en: "Inactive",
    },
    add: {
      ja: "追加",
      en: "Add",
    },
    create: {
      ja: "作成",
      en: "Create",
    },
    download: {
      ja: "ダウンロード",
      en: "Download",
    },
    resetMonth: {
      ja: "リセット",
      en: "Reset",
    },
    cancel: {
      ja: "キャンセル",
      en: "Cancel",
    },
    ok: {
      ja: "OK",
      en: "OK",
    },
    dateTime: {
      ja: "日時",
      en: "Date and Time",
    },
    langauge: {
      ja: "Language",
      en: "Language",
    },
    addImage: {
      ja: "画像を追加する",
      en: "Add Image",
    },
  },
  admin: {
    title: {
      ja: "システム管理者モード",
      en: "System Admin Mode",
    },
    headers: {
      users: {
        auth: {
          ja: "ユーザー（Authentication）",
          en: "Users (Authentication)",
        },
        firestore: {
          ja: "ユーザー（Firestore）",
          en: "Users (Firestore)",
        },
      },
    },
    users: {
      common: {
        name: {
          ja: "名前",
          en: "Name",
        },
        email: {
          ja: "メールアドレス",
          en: "Email Address",
        },
      },
      auth: {
        title: {
          ja: "現在のユーザー一覧（Authentication）",
          en: "Current Users List (Authentication)",
        },
        password: {
          ja: "パスワード",
          en: "Password",
        },
        reset: {
          ja: "再設定",
          en: "Reset",
        },
        mailAuth: {
          ja: "メール認証",
          en: "Email Authentication",
        },
        admin: {
          ja: "管理者",
          en: "Admin",
        },
        selfAdmin: {
          ja: "自分自身の管理権限は取り消せません。",
          en: "Your own administration privileges cannot be revoked.",
        },
        userCreated: {
          ja: "下記のユーザを作成しました",
          en: "The below user was created",
        },
        revokeAdmin: {
          ja: "本当に管理者権限を削除してもよいですか？",
          en: "Are you sure you want to revoke administration privilages?",
        },
        grantAdmin: {
          ja: "本当に管理者権限を追加してもよいですか？",
          en: "Are you sure you want to grant administration privilages?",
        },
        delete: {
          ja: "本当に管理者を削除してもよいですか？",
          en: "Are you sure you want to delete user?",
        },
        edit: {
          ja: "本当に管理者を編集してもよいですか？",
          en: "Are you sure you want to edit user?",
        },
        selfDelete: {
          ja: "自分のアカウントを削除しました。ログアウトします。",
          en: "You have deleted your account. You will now be logged out.",
        },
      },
      firestore: {
        title: {
          ja: "現在のユーザー一覧（Firestore）",
          en: "Current User List (Firestore)",
        },
      },
    },
    facilities: {
      title: {
        ja: "施設",
        en: "Facilities",
      },
      adminNumber: {
        ja: "管理者数",
        en: "Number of Admins",
      },
      userNumber: {
        ja: "ユーザー数",
        en: "Number of Users",
      },
      facilityName: {
        ja: "施設名",
        en: "Facility Name",
      },
      firstDeleteWarning: {
        ja: "施設を削除してもよいですか。",
        en: "Are you sure you want to delete this facility?",
      },
      secondDeleteWarning: {
        ja: "本当に施設を削除してもよいですか。削除された施設は復元できません。",
        en: "Are you sure you want to delete this facility? The deleted facility cannot be restored.",
      },
      secondDeleteWarning2: {
        ja: "削除が完了するまで操作しないで下さい。",
        en: "Please wait until deletion has completed.",
      },
      deleteComplete: {
        ja: "施設を削除しました。",
        en: "The facility was deleted.",
      },
    },
    sensors: {
      title: {
        ja: "センサー",
        en: "Sensors",
      },
      facility: {
        ja: "施設",
        en: "Facility",
      },
      type: {
        ja: "種別",
        en: "Type",
      },
      active: {
        ja: "活性",
        en: "Active",
      },
      deleteFacility: {
        ja: "本当に削除してよろしいですか？よい場合は確認後、保存ボタンを押してください。",
        en: "Are you sure you want to delete this facility？If you are sure, please click the Save button after confirming.",
      },
      id: {
        ja: "センサーID",
        en: "Sensor ID",
      },
      nameUse: {
        ja: "システム管理用の名称で、ユーザー側には表示されません。",
        en: "This name is for System Admins and will not be displayed to users.",
      },
      motion: {
        ja: "人感",
        en: "Motion",
      },
      others: {
        ja: "その他",
        en: "Others",
      },
      deleteMessage: {
        ja: "本当にセンサーを削除してよいですか？",
        en: "Are you sure you want to delete this sensor?",
      },
    },
    robots: {
      title: {
        ja: "ロボット",
        en: "Robots",
      },
      facility: {
        ja: "施設",
        en: "Facility",
      },
      active: {
        ja: "活性",
        en: "Active",
      },
      id: {
        ja: "ロボットID",
        en: "Robot ID",
      },
      siteName: {
        ja: "サイト名",
        en: "Site Name",
      },
      siteId: {
        ja: "サイトID",
        en: "Site ID",
      },
      nameUse: {
        ja: "システム管理用の名称で、ユーザー側には表示されません。",
        en: "This name is for System Admins and will not be displayed to users.",
      },
      deleteFacility: {
        ja: "本当に削除してよろしいですか？よい場合は確認後、保存ボタンを押してください。",
        en: "Are you sure you want to delete this facility？If you are sure, please click the Save button after confirming.",
      },
      type: {
        ja: "機種",
        en: "Type",
      },
      name: {
        ja: "ロボット名",
        en: "Robot Name",
      },
      deleteMessage: {
        ja: "本当にロボットを削除してもよいですか？",
        en: "Are you sure you want to delete this robot?",
      },
    },
    kpi: {
      failFetch: {
        en: "Cant fetch Kpi Data",
        ja: "エラーが発生しました",
      },

      kpiNoData: {
        en: "No KPI data found",
        ja: "KPIが登録されていません",
      },
      kpiDataSuccess: {
        en: "Updated successfully!",
        ja: "更新しました",
      },
      tab: {
        ja: "KPI",
        en: "KPI",
      },

      kpiSettings: {
        en: "KPI Settings",
        ja: "KPI 設定",
      },
      title: {
        ja: "KPIダッシュボード",
        en: "KPI Dashboard",
      },
      customerSatisfaction: {
        ja: "顧客満足度",
        en: "Customer Satisfaction",
      },
      facilityCleanliness: {
        ja: "綺麗度",
        en: "Facility Cleanliness",
      },
      carbonFootprint: {
        ja: "温室効果ガス排出量",
        en: "Carbon Footprint",
      },
      meter: {
        ja: "平米数",
        en: "Square meters",
      },
      co2: {
        ja: "二酸化炭素",
        en: "Co2",
      },
      robotFloorCoverage: {
        ja: "ロボット清掃面積",
        en: "Robot Floor Coverage",
      },
      janitorCoverage: {
        ja: "清掃員アクティビティ",
        en: "Janitor Activity",
      },

      taskReport: {
        en: "Cleaning Staff Productivity",
        ja: "清掃員の生産性",
      },
      onDemandCleaningResponse: {
        ja: "オンデマンド清掃対応数",
        en: "On Demand Cleaning Response",
      },
      cleaningRequestResponse: {
        ja: "清掃リクエスト対応数",
        en: "Cleaning Request Response",
      },
      peopleCount: {
        ja: "人数カウント",
        en: "People Count",
      },
      facilityId: {
        ja: "施設ID",
        en: "Facility ID",
      },
      active: {
        ja: "活性",
        en: "Active",
      },
      deleteMessage: {
        ja: "本当にKPIを削除してよいですか？",
        en: "Are you sure you want to delete this KPI?",
      },
    },
    common: {
      name: {
        ja: "名称",
        en: "Name",
      },
      edit: {
        ja: "編集",
        en: "Edit",
      },
      add: {
        ja: "追加",
        en: "Add",
      },
      complete: {
        ja: "完了",
        en: "Complete",
      },
      save: {
        ja: "保存",
        en: "Save",
      },
      delete: {
        ja: "削除",
        en: "Delete",
      },
      cancel: {
        ja: "キャンセル",
        en: "Cancel",
      },
      send: {
        ja: "送信",
        en: "Send",
      },
    },
  },
  header: {
    textSize: {
      ja: "文字サイズ",
      en: "Text size",
    },
    profile: {
      ja: "プロフィール",
      en: "Profile",
    },
    logout: {
      ja: "ログアウト",
      en: "Logout",
    },
    menu: {
      ja: "メニュー",
      en: "Menu",
    },
    small: {
      ja: "文字を小さくする",
      en: "Make text smaller",
    },
    big: {
      ja: "文字を大きくする",
      en: "Make text bigger",
    },
  },
  login: {
    login: {
      ja: "ログイン",
      en: "Login",
    },
    email: {
      ja: "メールアドレス",
      en: "Email Address",
    },
    password: {
      ja: "パスワード",
      en: "Password",
    },
  },
  home: {
    news: {
      ja: "お知らせ",
      en: "News",
    },
    btnQr: {
      ja: "二次元バーコードを発行・変更・削除する",
      en: "Generate / modify / delete 2D barcode",
    },
    btnSensor: {
      ja: "センサーを管理する",
      en: "Manage Sensors",
    },
    btnAtp: {
      ja: "ATPを登録・変更・削除する",
      en: "Register / modify / delete ATPs",
    },
    btnDashboard: {
      ja: "現在の施設の状況を確認する",
      en: "Check current facility status",
    },
    btnReport: {
      ja: "レポートを作成・確認する",
      en: "Create / check reports.",
    },
    btnUser: {
      ja: "メンバーを登録・変更・削除する",
      en: "Register / modify / delete Users",
    },
    btnMaps: {
      ja: "マップを編集する",
      en: "Edit maps",
    },
    btnFacility: {
      ja: "施設情報を変更する",
      en: "Edit facility information",
    },
    btnRobot: {
      ja: "ロボットを管理する",
      en: "Manage Robots",
    },
    btnTimeZone: {
      ja: "施設設定を管理する",
      en: "Facility Settings",
    },
  },

  timezoneCollection: {
    format: {
      ja: "YYYY年MM月DD日  HH時mm分",
      en: "YYYY/MM/DD   hh:mm a",
    },

    alerts: {
      ja: "タイムゾーン更新しました。",
      en: "Time Zone updated successfully",
    },
    timezoneHeading: {
      en: "Time Zone Selection",
      ja: "タイムゾーン",
    },

    MenuItem: {
      singapore: {
        en: "Asia/Singapore",
        ja: "シンガポール",
      },
      japan: {
        en: "Asia/Tokyo",
        ja: "日本",
      },

      Seoul: {
        en: "Asia/Seoul",
        ja: "ソウル",
      },
      NZ: {
        en: "New Zealand",
        ja: "ニュージーランド",
      },
      Dubai: {
        en: "Asia/Dubai",
        ja: "アジア/ドバイ",
      },
      Riyadh: {
        en: "Asia/Riyadh",
        ja: "アジア/リヤド",
      },
      Johannesburg: {
        en: "Africa/Johannesburg",
        ja: "アフリカ/ヨハネスブルグ",
      },
    },

    labels: {
      select: {
        ja: "タイムゾーンの選択",
        en: "Time Zone Selection",
      },
      RegionSelect: {
        ja: "タイムゾーン",
        en: "Time Zone",
      },
      CurrentTime: {
        en: "Current Time:",
        ja: "現在の時間:",
      },
    },

    languageStrings: {
      australiaCitiesFilter: {
        Adelaide: "Australia/Adelaide",
        Brisbane: "Australia/Brisbane",
        Canberra: "Australia/Canberra",
        Darwin: "Australia/Darwin",
        Hobart: "Australia/Hobart",
        Melbourne: "Australia/Melbourne",
        Perth: "Australia/Perth",
        Sydney: "Australia/Sydney",
      },

      europeCitiesFilter: {
        Andorra: "Europe/Andorra",
        Helsinki: "Europe/Helsinki",
        Mariehamn: "Europe/Mariehamn",
        Belgrade: "Europe/Belgrade",
        Sarajevo: "Europe/Sarajevo",
        Brussels: "Europe/Brussels",
        Sofia: "Europe/Sofia",
        Minsk: "Europe/Minsk",
        Zurich: "Europe/Zurich",
        Prague: "Europe/Prague",
        Berlin: "Europe/Berlin",
        Busingen: "Europe/Busingen",
        Copenhagen: "Europe/Copenhagen",
        Tallinn: "Europe/Tallinn",
        Madrid: "Europe/Madrid",
        Paris: "Europe/Paris",
        London: "Europe/London",
        Guernsey: "Europe/Guernsey",
        Gibraltar: "Europe/Gibraltar",
        Athens: "Europe/Athens",
        Zagreb: "Europe/Zagreb",
        Budapest: "Europe/Budapest",
        Dublin: "Europe/Dublin",
        Isle_of_Man: "Europe/Isle_of_Man",
        Rome: "Europe/Rome",
        Jersey: "Europe/Jersey",
        Vaduz: "Europe/Vaduz",
        Vilnius: "Europe/Vilnius",
        Luxembourg: "Europe/Luxembourg",
        Riga: "Europe/Riga",
        Monaco: "Europe/Monaco",
        Chisinau: "Europe/Chisinau",
        Podgorica: "Europe/Podgorica",
        Skopje: "Europe/Skopje",
        Malta: "Europe/Malta",
        Amsterdam: "Europe/Amsterdam",
        Oslo: "Europe/Oslo",
        Warsaw: "Europe/Warsaw",
        Lisbon: "Europe/Lisbon",
        Bucharest: "Europe/Bucharest",
        Stockholm: "Europe/Stockholm",
        Ljubljana: "Europe/Ljubljana",
        Bratislava: "Europe/Bratislava",
        San_Marino: "Europe/San_Marino",
        Istanbul: "Europe/Istanbul",
        Kyiv: "Europe/Kyiv",
        Simferopol: "Europe/Simferopol",
        Vatican: "Europe/Vatican",
      },

      cityNames: {
        Sydney: {
          ja: "シドニー",
          en: "Sydney",
        },
        Johannesburg: {
          en: "Johannesburg",
          ja: "ヨハネスブルグ",
        },
        Melbourne: {
          ja: "メルボルン",
          en: "Melbourne",
        },
        Brisbane: {
          ja: "ブリスベン",
          en: "Brisbane",
        },
        Perth: {
          ja: "パース",
          en: "Perth",
        },
        Adelaide: {
          ja: "アデレード",
          en: "Adelaide",
        },
        Hobart: {
          ja: "ホバート",
          en: "Hobart",
        },
        Darwin: {
          ja: "ダーウィン",
          en: "Darwin",
        },
        Canberra: {
          ja: "キャンベラ",
          en: "Canberra",
        },
        Singapore: {
          ja: "シンガポール",
          en: "Singapore",
        },
        Tokyo: {
          ja: "東京",
          en: "Tokyo",
        },
        NZ: {
          en: "New Zealand",
          ja: "ニュージーランド",
        },
        Seoul: {
          ja: "ソウル",
          en: "Seoul",
        },
        Andorra: {
          ja: "アンドラ",
          en: "Andorra",
        },
        Dubai: {
          ja: "ドバイ",
          en: "Dubai",
        },
        Helsinki: {
          ja: "ヘルシンキ",
          en: "Helsinki",
        },
        Mariehamn: {
          ja: "マリーハムン",
          en: "Mariehamn",
        },
        Belgrade: {
          ja: "ベオグラード",
          en: "Belgrade",
        },
        Sarajevo: {
          ja: "サラエボ",
          en: "Sarajevo",
        },
        Brussels: {
          ja: "ブリュッセル",
          en: "Brussels",
        },
        Sofia: {
          ja: "ソフィア",
          en: "Sofia",
        },
        Minsk: {
          ja: "ミンスク",
          en: "Minsk",
        },
        Zurich: {
          ja: "チューリッヒ",
          en: "Zurich",
        },
        Prague: {
          ja: "プラハ",
          en: "Prague",
        },
        Berlin: {
          ja: "ベルリン",
          en: "Berlin",
        },
        Busingen: {
          ja: "ビューゼンゲン",
          en: "Busingen",
        },
        Copenhagen: {
          ja: "コペンハーゲン",
          en: "Copenhagen",
        },
        Tallinn: {
          ja: "タリン",
          en: "Tallinn",
        },
        Madrid: {
          ja: "マドリード",
          en: "Madrid",
        },
        Paris: {
          ja: "パリ",
          en: "Paris",
        },
        London: {
          ja: "ロンドン",
          en: "London",
        },
        Guernsey: {
          ja: "ガーンジー",
          en: "Guernsey",
        },
        Gibraltar: {
          ja: "ジブラルタル",
          en: "Gibraltar",
        },
        Athens: {
          ja: "アテネ",
          en: "Athens",
        },
        Zagreb: {
          ja: "ザグレブ",
          en: "Zagreb",
        },
        Budapest: {
          ja: "ブダペスト",
          en: "Budapest",
        },
        Dublin: {
          ja: "ダブリン",
          en: "Dublin",
        },
        Isle_of_Man: {
          ja: "マン島",
          en: "Isle of Man",
        },
        Rome: {
          ja: "ローマ",
          en: "Rome",
        },
        Jersey: {
          ja: "ジャージー",
          en: "Jersey",
        },
        Vaduz: {
          ja: "ファドゥーツ",
          en: "Vaduz",
        },
        Vilnius: {
          ja: "ビリニュス",
          en: "Vilnius",
        },
        Luxembourg: {
          ja: "ルクセンブルク",
          en: "Luxembourg",
        },
        Riga: {
          ja: "リガ",
          en: "Riga",
        },
        Monaco: {
          ja: "モナコ",
          en: "Monaco",
        },
        Chisinau: {
          ja: "キシナウ",
          en: "Chisinau",
        },
        Podgorica: {
          ja: "ポドゴリツァ",
          en: "Podgorica",
        },
        Skopje: {
          ja: "スコピエ",
          en: "Skopje",
        },
        Malta: {
          ja: "マルタ",
          en: "Malta",
        },
        Amsterdam: {
          ja: "アムステルダム",
          en: "Amsterdam",
        },
        Oslo: {
          ja: "オスロ",
          en: "Oslo",
        },
        Warsaw: {
          ja: "ワルシャワ",
          en: "Warsaw",
        },
        Lisbon: {
          ja: "リスボン",
          en: "Lisbon",
        },
        Bucharest: {
          ja: "ブカレスト",
          en: "Bucharest",
        },
        Riyadh: {
          ja: "リヤド",
          en: "Riyadh",
        },
        Stockholm: {
          ja: "ストックホルム",
          en: "Stockholm",
        },
        Ljubljana: {
          ja: "リュブリャナ",
          en: "Ljubljana",
        },
        Bratislava: {
          ja: "ブラチスラヴァ",
          en: "Bratislava",
        },
        San_Marino: {
          ja: "サンマリノ",
          en: "San Marino",
        },
        Istanbul: {
          ja: "イスタンブール",
          en: "Istanbul",
        },
        Kyiv: {
          ja: "キエフ",
          en: "Kyiv",
        },
        Simferopol: {
          ja: "シンフェロポリ",
          en: "Simferopol",
        },
        Vatican: {
          ja: "バチカン",
          en: "Vatican",
        },
      },
    },
  },

  rootHome: {
    selectFacility: {
      ja: "施設を選択してください",
      en: "Choose facility.",
    },
    adminMode: {
      ja: "アドミンモード",
      en: "ADMIN MODE",
    },
    mailTitle: {
      ja: "メール認証が必要です",
      en: "Email verification is required",
    },
    mail1: {
      ja: "メール認証が完了していません。このままだとお客様がリクエストした際にエラーとなります。",
      en: "Email verification has not been completed. If you do not, an error will occur when you make a request. Please complete the email verification ",
    },
    mail2: {
      ja: "こちら",
      en: "here",
    },
    mail3: {
      ja: "からメール認証を完了してください。",
      en: ".",
    },
    alert: {
      ja: "施設管理者の方は、PCのみアクセス可能です。",
      en: "Facility managers can only access the PC.",
    },
  },
  account: {
    backHome: {
      ja: "ホームに戻る",
      en: "Back to Home",
    },
    profile: {
      title: {
        ja: "プロフィール変更",
        en: "Edit Profile",
      },
    },
    verification: {
      title: {
        ja: "メール認証",
        en: "Email Verification",
      },
      content: {
        ja: "メールアドレスがまだ認証されていません。通知メールは認証後、送信されます。",
        en: "Your email address has not been verified. Notifications will be sent after verification.",
      },
      button: {
        ja: "認証メールを送信",
        en: "Send Verification Email",
      },
    },
    name: {
      title: {
        ja: "名前を変更する",
        en: "Edit Name",
      },
      button: {
        ja: "名前を変更",
        en: "Edit Name",
      },
    },
    email: {
      title: {
        ja: "メールアドレスを変更する",
        en: "Edit Email",
      },
      new: {
        ja: "新しいメールアドレス",
        en: "New Email Address",
      },
      confirmation: {
        ja: "新しいメールアドレス(再入力)",
        en: "New Email Address (Confirmation)",
      },
      button: {
        ja: "メールアドレスを変更",
        en: "Edit Email Address",
      },
    },
    password: {
      title: {
        ja: "パスワードを変更する",
        en: "Edit Password",
      },
      current: {
        ja: "現在のパスワード",
        en: "Current Password",
      },
      new: {
        ja: "新しいパスワード",
        en: "New Password",
      },
      confirmation: {
        ja: "新しいパスワード(再入力)",
        en: "New Password (Confirmation)",
      },
      button: {
        ja: "パスワードを変更",
        en: "Change Password",
      },
    },
  },
  alert: {
    nameUpdated: {
      en: "Updated Successfully.",
      ja: "更新しました。",
    },
    resetAllSensor: {
      label: {
        en: "Reset Facility Sensors",
        ja: "施設センサーリセット",
      },

      button: {
        en: "Reset all sensor reset times",
        ja: "センサーリセットの時間を全てリセット",
      },
      Success: {
        en: "All sensor reset times have been reset.",
        ja: "センサーリセットの時間がすべてリセットされました",
      },
      SuccessResetTimeSetAll: {
        en: "Sensor reset time has been set.",
        ja: "センサーリセットの時間が設定されました。",
      },
      noValue: {
        en: "Please select the Reset Time. ",
        ja: "リセット時間を選んでください。",
      },
      Fail: {
        en: "There was an error resetting the values of the sensors",
        ja: "センサーの値をリセットする際にエラーが発生しました。",
      },
    },

    cannotDeleteArea: {
      ja: "トリガーや二次元バーコードのロボットエリアに含まれているエリアを削除することはできません。まずはロボットエリアから解除してください。",
      en: "Areas included in triggers and 2D barcode destinations cannot be deleted. Please remove it from the robot area first.",
    },
    cannotDeleteFloor: {
      ja: "トリガーや二次元バーコードのロボットエリアに含まれているフロアを削除することはできません。まずはロボットエリアから解除してください。",
      en: "Floors included in triggers and 2D barcode destinations cannot be deleted. Please remove it from the robot area first.",
    },
    sensorStatusGood: {
      ja: "すべてのセンサーは正常です",
      en: "All sensor are normal.",
    },
    sensorStatusError: {
      ja: "正常でないセンサーがあります",
      en: "Bad sensor exists",
    },
    sensorBatteryGood: {
      ja: "すべてのセンサーの電池残量は充分です",
      en: "All sensors have sufficient battery power.",
    },
    sensorBatteryError: {
      ja: "電池残量が残りわずかなセンサーがあります",
      en: "Some sensors have low battery",
    },
    sensorResetFailed: {
      ja: "センサーの値をリセットできませんでした",
      en: "Unable to reset sensor value",
    },
    sendEmail: {
      ja: "メールを送信しました",
      en: "Sent Email",
    },
    emailChangeSuccess: {
      ja: "正常に変更されました。セキュリティのため一度ログアウトします。",
      en: "Your email has been successfully edited. You will now be logged out for security purposes.",
    },
    accessDenied: {
      ja: "権限がありません",
      en: "Access Denied",
    },
    alreadyAdded: {
      ja: "既に追加されたユーザーです",
      en: "User has already been added",
    },
    notRegistered: {
      ja: "メールアドレスが登録されていません",
      en: "Email address has not been registered",
    },
    updated: {
      ja: "更新しました",
      en: "Updated",
    },
    edited: {
      ja: "編集しました",
      en: "Edited",
    },
    added: {
      ja: "追加しました",
      en: "Added",
    },
    deleted: {
      ja: "削除しました",
      en: "Deleted",
    },
    file: {
      ja: "ファイルは10MB以内のPNGまたはJPEGファイルである必要があります。",
      en: "The file must be a PNG or JPEG file no larger than 10 MB.",
    },
    atpFile: {
      ja: "ファイルは10MB以内のPNGまたはJPEGファイルである必要があります。",
      en: "The file must be a PNG or JPEG file no larger than 10 MB.",
    },
    error: {
      default: {
        ja: "エラーが発生しました",
        en: "An error has occured",
      },
    },
    alreadyExists: {
      ja: "すでに存在します",
      en: "Already exists",
    },
    userCreateFailed: {
      ja: "ユーザー作成に失敗しました",
      en: "User Creation Failed",
    },
    errorOccurred: {
      ja: "エラーが発生しました。時間をおいて再度、送信してください。",
      en: "An error has occurred. Please wait a moment and try again.",
    },
    oneRobotOnly: {
      ja: "登録できるエリアは、ロボット1台につき1つのみです",
      en: "Only 1 area per robot can be registered.",
    },
    cleaningModeNoRobots: {
      ja: "ロボットエリアを1つ以上選択してください。",
      en: "Please select one or more robot areas.",
    },
    noRobots: {
      ja: "掃除できるロボットがありません。しばらくお待ちしてからもう一度やり直してください。",
      en: "No available robots to clean. Please wait and try again later.",
    },
    robotDoesNotExist: {
      ja: "ロボットが存在しません。",
      en: "Robot does not exist.",
    },
    cleaningModesDoNotExist: {
      ja: "APIにクリーニングモードが存在しません。",
      en: "There are not cleaning modes in the API.",
    },
    successRobotStart: {
      ja: "ロボットが開始しました。",
      en: "The robot was unable to clean. Please try again later.",
    },
    failRobotStart: {
      ja: "ロボットが清掃ができませんでした、時間をあけて再度お試しください。",
      en: "The robot was unable to clean. Please try again later.",
    },
  },
  dashboard: {
    hours: {
      ja: "時間",
      en: "Hours",
    },
    incomplete: {
      ja: "未完了の作業",
      en: "Incomplete Tasks",
    },
    complete: {
      ja: "完了した作業",
      en: "Completed Tasks",
    },
    display: {
      ja: " (表示件数：",
      en: " (Displayed：",
    },
    item: {
      ja: "件)",
      en: "tasks)",
    },
    main: {
      ja: "SBX Connectは素晴らしいビルマネジメントのソリューションを提供します。",
      en: "SBX Connect provides a great building management solution.",
    },
    recentNotification: {
      ja: "最近の通知",
      en: "Recent notification",
    },
    dashboard: {
      ja: "ダッシュボード",
      en: "Dashboard",
    },
    recentNotificationTableDate: {
      ja: "日時",
      en: "Date",
    },
    recentNotificationTablePlace: {
      ja: "設置場所",
      en: "Place",
    },
    recentNotificationTableRequest: {
      ja: "依頼事項",
      en: "Request",
    },
    recentNotificationTableImage: {
      ja: "画像",
      en: "Image",
    },
    recentNotificationTableDone: {
      ja: "対応",
      en: "Done",
    },
    todaysSchedule: {
      ja: "本日の予定",
      en: "Today's schedule",
    },
    date: {
      ja: "日付",
      en: "Date",
    },
    dateCreated: {
      ja: "作成日時",
      en: "Date Created",
    },
    detail: {
      ja: "詳細",
      en: "Detail",
    },
    robot: {
      ja: "ロボット",
      en: "Robot",
    },
    type: {
      ja: "種類",
      en: "Type",
    },
    content: {
      ja: "内容",
      en: "Content",
    },
    place: {
      ja: "場所/名称",
      en: "Place/Name",
    },
    name: {
      ja: "名称",
      en: "Name",
    },
    assignee: {
      ja: "担当",
      en: "Assignee",
    },
    status: {
      ja: "状況",
      en: "Status",
    },
    sensor: {
      ja: "センサー",
      en: "Sensors",
    },
    kpi: {
      downloadAll: {
        ja: "全てダウンロード",
        en: "Download All",
      },
      facilityCleanliness: {
        title: {
          ja: "施設清潔度",
          en: "Facility Cleanliness",
        },
        description: {
          ja: "ATP検査による施設の清潔度を表す指標",
          en: "An indicator of facility cleanliness based on ATP testing",
        },
        place: {
          ja: "箇所",
          en: " Place(s)",
        },
        levels: {
          veryClean: {
            ja: "とてもきれい",
            en: "Very Clean",
          },
          clean: {
            ja: "きれい",
            en: "Clean",
          },
          dirty: {
            ja: "やや汚れている",
            en: "Dirty",
          },
          veryDirty: {
            ja: "とても汚れている",
            en: "Very Dirty",
          },
        },
        ratings: {
          good: {
            ja: "良い",
            en: "Good",
          },
          recommenders: {
            ja: "推奨者",
            en: "Recommenders",
          },
          neutrals: {
            ja: "中立者",
            en: "Neutrals",
          },
          critics: {
            ja: "批判者",
            en: "Critics",
          },
        },
      },
      janitorCoverage: {
        title: {
          ja: "清掃員アクティビティ",
          en: "Janitor Coverage",
        },
        dataKey: {
          ja: "出勤",
          en: "Clock In",
        },
        yearMonthFormat: {
          ja: "YYYY年M月",
          en: "YYYY/M",
        },
      },

      robotFloorCoverage: {
        dataKey: {
          ja: "m2",
          en: "m2",
        },
        title: {
          ja: "ロボット清掃面積",
          en: "Robot Floor Coverage",
        },

        yearMonthFormat: {
          ja: "YYYY年M月",
          en: "YYYY/M",
        },
      },

      cleaningRequest: {
        title: {
          ja: "清掃リクエスト対応数",
          en: "Cleaning Request Response",
        },
      },
      personInChargeWorkTime: {
        title: {
          ja: "担当者別の作業時間​",
          en: "Cleaning Time - Cleaning Staff",
        },
      },
      workingHoursByPlace: {
        title: {
          ja: "清掃場所別の作業時間",
          en: "Cleaning Time - Location",
        },
      },
      onDemandCleaning: {
        title: {
          ja: "オンデマンド清掃対応数",
          en: "On Demand Cleaning Response",
        },
      },
      request: {
        incompleteRequests: {
          ja: "未完了",
          en: "Incomplete",
        },
        completedRequests: {
          ja: "完了",
          en: "Completed",
        },
        OperationTime: {
          en: "Total Time",
          ja: "作業時間",
        },
        Person: {
          en: "In Charge",
          ja: "担当者",
        },
        place: {
          en: "Place",
          ja: "作業場所",
        },
        month: {
          ja: "YYYY年M月",
          en: "YYYY/M",
        },
      },
      peopleCount: {
        title: {
          ja: "人数カウント",
          en: "People Count",
        },
      },
      peopleCountAll: {
        title: {
          ja: "人数カウント(全て)",
          en: "People Count (All)",
        },
        dataKey: {
          ja: "人数",
          en: "People",
        },
        yearMonthFormat: {
          ja: "YYYY年M月",
          en: "YYYY/M",
        },
      },
      peopleCountSensor: {
        title: {
          ja: "人数カウント(登録したセンサー)",
          en: "People Count (Registered Sensors)",
        },
      },
    },
  },
  users: {
    description: {
      ja: "この施設に紐づけられたユーザー",
      en: "Users associated with this facility.",
    },
    email: {
      ja: "メールアドレス",
      en: "Email address",
    },
    role: {
      ja: "役割",
      en: "Role",
    },
    status: {
      ja: "ステータス",
      en: "Status",
    },
    displayName: {
      ja: "名前",
      en: "Name",
    },
    statusName: {
      0: {
        ja: "退勤",
        en: "Clocked Out",
      },
      1: {
        ja: "出勤",
        en: "Clocked In",
      },
      2: {
        ja: "",
        en: "",
      },
    },
    self: {
      ja: "自分を変更することはできません",
      en: "Users are unable to edit themselves",
    },
    remove: {
      ja: "トリガーや二次元バーコードの通知先に含まれているユーザーを削除することはできません。まずは通知先から解除してください。",
      en: "Users included in triggers and 2D barcode destinations cannot be deleted. Please remove it from the notification destination first.",
    },
    none: {
      ja: "いません",
      en: "No Users",
    },
    deleteDialog: {
      title: {
        ja: "ユーザーを施設から削除します",
        en: "Delete user from this facility",
      },
      message: {
        ja: "本当に削除してもよいですか？",
        en: "Are you sure?",
      },
    },
    activityReport: {
      title: {
        ja: "出退勤レポート",
        en: "Clock In/Out Report",
      },
      button: {
        ja: "ダウンロード",
        en: "Download",
      },
      date: {
        format: {
          ja: "YYYY年MM月",
          EN: "MMMM YY",
        },
      },
      error: {
        ja: "日付を選択してください",
        en: "Please Select a Date",
      },
      none: {
        ja: "出退勤履歴がありません",
        en: "No Clock In/Out History",
      },
    },
  },
  userAdd: {
    title: {
      ja: "ユーザーを追加する",
      en: "Add User",
    },
    email: {
      ja: "メールアドレス",
      en: "Email Address",
    },
    role: {
      ja: "役割",
      en: "Role",
    },
    errorEmail: {
      ja: "正しいメールアドレスではありません",
      en: "Invalid email address.",
    },
    button: {
      ja: "ユーザーを追加",
      en: "Add User",
    },
  },
  sensor: {
    description: {
      ja: "この施設に紐づけられたセンサー",
      en: "Sensors associated with this facility.",
    },
    table: {
      item: {
        ja: "項目",
        en: "Item",
      },
      content: {
        ja: "内容",
        en: "Content",
      },
      id: {
        ja: "ID",
        en: "ID",
      },
      type: {
        ja: "タイプ",
        en: "Type",
      },
      name: {
        ja: "名称",
        en: "Name",
      },
      coordinates: {
        ja: "座標",
        en: "Coordinates",
      },
      status: {
        ja: "ステータス",
        en: "Status",
      },
      battery: {
        ja: "電池残量",
        en: "Battery",
      },
      condition: {
        ja: "条件",
        en: "Condition",
      },
      threshold: {
        ja: "しきい値",
        en: "Threshold",
      },
      value: {
        ja: "現在値",
        en: "Current Value",
      },
      lastUpdated: {
        ja: "最終更新",
        en: "Last Updated",
      },
    },
    triggers: {
      trigger: {
        ja: "トリガー",
        en: "Trigger",
      },
      triggers: {
        ja: "トリガー",
        en: "Triggers",
      },
    },
    none: {
      ja: "ありません",
      en: "No Sensors",
    },
    process: {
      ja: "受け取った値の処理",
      en: "Received Value Process",
    },
    increase: {
      ja: "加算",
      en: "Increase",
    },
    increaseExplanation: {
      ja: "新しく受け取った値を現在の値に足していきます",
      en: "Add the newly received values to the current value",
    },
    decrease: {
      ja: "減算",
      en: "Decrease",
    },
    decreaseExplanation: {
      ja: "新しく受け取った値を現在の値から引いていきます",
      en: "Subtract the newly received values to the current value",
    },
    maintain: {
      ja: "なし",
      en: "None",
    },
    maintainExplanation: {
      ja: "センサーから受け取った値をそのまま利用します",
      en: "Maintain the value received from the sensor",
    },
    reset: {
      ja: "リセット",
      en: "Reset",
    },
    currentResetTime: {
      ja: "現在のリセット時間",
      en: "Current Reset TIme",
    },
    automaticReset: {
      ja: "自動リセット設定（加算または減算のみ）",
      en: "Automatic Reset Setting (Increase or Decrease Only)",
    },
    activeExplanation: {
      ja: "紐づけられたタスクが完了したら値を指定の値にします",
      en: "When the associated task is completed, the value is set to the specified value",
    },
    inactiveExplanation: {
      ja: "値のリセットは手動のみ可能になります",
      en: "Only manual resetting of values will be possible",
    },
    triggerNote: {
      ja: "※最後の通知後、10分間は設定したトリガー条件を満たしても再度通知はされません。",
      en: "※Notifications will not be sent again for 10 minutes after the last notification, even if the set trigger conditions are met.",
    },
    resetTimeSelection: {
      ja: "施設センサーリセット時間",
      en: "Sensor Reset Time",
    },
    resetSensorFacilities: {
      en: "Sensor Reset Time",
      ja: "センサーリセット時間",
    },
    currentLocation: {
      ja: "現在地",
      en: "Current location",
    },
    iBeaconHolder: {
      ja: "iBeacon所持者",
      en: "iBeacon holder",
    },

    currentValue: {
      value: {
        ja: "現在の値:",
        en: "Current Value:",
      },
      resetTime: {
        ja: "リセット時間",
        en: "Reset time",
      },
      resetTimeError: {
        ja: "リセット時間が設定されていません",
        en: "There is no Reset Time set as of now",
      },
      resetTimeSuccess: {
        ja: "リセット時間が正常に更新されました",
        en: "Reset Time Has been Updated Successfully",
      },

      resetTimeLabel: {
        ja: "リセット時間選択",
        en: "Reset Time Selection",
      },
    },

    trigger: {
      threshold: {
        ja: "閾値",
        en: "Threshold",
      },
      assignee: {
        ja: "通知先",
        en: "Assignee",
      },
      cleaningMode: {
        ja: "ロボットクリーニングモード",
        en: "Robot Cleaning Mode",
      },
      robotAreas: {
        ja: "ロボットエリア",
        en: "Robot Areas",
      },
      operator: {
        ja: "演算子",
        en: "Operator",
      },
      errors: {
        noAssignee: {
          ja: "通知先を選択してください",
          en: "Please select an assignee",
        },
      },
    },
    history: {
      ja: "履歴",
      en: "History",
    },
    recent: {
      ja: "直近100件",
      en: "Most recent 100",
    },
    zero: {
      ja: "ゼロを除外",
      en: "Exclude zero",
    },
    value: {
      ja: "値",
      en: "Value",
    },
    date: {
      ja: "日時",
      en: "Date and Time",
    },
    terms: {
      title: {
        ja: "条件",
        en: "terms",
      },
      orMore: {
        ja: "以上",
        en: "or more",
      },
      orLess: {
        ja: "以下",
        en: "or less",
      },
      moreThan: {
        ja: "より大きい",
        en: "more than",
      },
      lessThan: {
        ja: "より小さい",
        en: "less than",
      },
      equal: {
        ja: "等しい",
        en: "equal",
      },
      notEqual: {
        ja: "等しくない",
        en: "not equal",
      },
    },
  },
  robots: {
    description: {
      ja: "この施設に紐づけられたロボット",
      en: "Robots associated with this facility",
    },
    none: {
      ja: "ありません",
      en: "No Robots",
    },
    undefined: {
      ja: "未設定",
      en: "Undefined",
    },
    table: {
      id: {
        ja: "ID",
        en: "ID",
      },
      name: {
        ja: "ロボット名",
        en: "Robot Name",
      },
      type: {
        ja: "機種",
        en: "Type",
      },
    },
    detail: {
      table: {
        item: {
          ja: "項目",
          en: "Item",
        },
        content: {
          ja: "内容",
          en: "Content",
        },
      },
      cleaningModes: {
        title: {
          ja: "クリーニングモード",
          en: "Cleaning Mode",
        },
        day: {
          title: {
            ja: "曜日",
            en: "Day",
          },
          short: {
            0: {
              ja: "月",
              en: "MON",
            },
            1: {
              ja: "火",
              en: "TUE",
            },
            2: {
              ja: "水",
              en: "WED",
            },
            3: {
              ja: "木",
              en: "THU",
            },
            4: {
              ja: "金",
              en: "FRI",
            },
            5: {
              ja: "土",
              en: "SAT",
            },
            6: {
              ja: "日",
              en: "SUN",
            },
          },
          long: {
            0: {
              ja: "月曜",
              en: "Monday",
            },
            1: {
              ja: "火曜",
              en: "Tuesday",
            },
            2: {
              ja: "水曜",
              en: "Wednesday",
            },
            3: {
              ja: "木曜",
              en: "Thursday",
            },
            4: {
              ja: "金曜",
              en: "Friday",
            },
            5: {
              ja: "土曜",
              en: "Saturday",
            },
            6: {
              ja: "日曜",
              en: "Sunday",
            },
          },
        },
        mode: {
          title: {
            ja: "モード",
            en: "Mode",
          },
          清洗: {
            ja: "スクラブ",
            en: "Scrub",
          },
          清扫: {
            ja: "スイープ",
            en: "Sweep",
          },
          尘推: {
            ja: "モップ",
            en: "Mop",
          },
          吸尘: {
            ja: "バキューム",
            en: "Vacuum",
          },
        },
        cleaningModeDeleted: {
          ja: "登録しているロボットクリーニングモードが無くなりました。もう一度ロボットクリーニングモードを選択してください。",
          en: "The registered robot cleaning mode has been removed. Please select a robot cleaning mode again.",
        },
        updateCleaningMode: {
          ja: "無くなったロボットクリーニングモードの中に、紐づけされた二次元バーコード又はセンサートリガーがあります。",
          en: "There are linked 2D barcodes and/or sensor triggers related to the removed robot cleaning modes.",
        },
      },
      floors: {
        title: {
          ja: "フロア",
          en: "Floors",
        },
        index: {
          ja: "インデックス",
          en: "Index",
        },
        name: {
          ja: "名称",
          en: "Name",
        },
        mapId: {
          ja: "マップID",
          en: "Map ID",
        },
        areas: {
          ja: "エリア",
          en: "Areas",
        },
      },
    },
  },
  robotFloor: {
    add: {
      title: {
        ja: "フロアを追加する",
        en: "Add Floor",
      },
      button: {
        ja: "フロアを追加",
        en: "Add Floor",
      },
    },
    edit: {
      title: {
        ja: "フロアを編集する",
        en: "Edit Floor",
      },
      button: {
        ja: "フロアを編集",
        en: "Edit Floor",
      },
    },
    delete: {
      button: {
        ja: "フロアを削除",
        en: "Delete Floor",
      },
    },
    index: {
      ja: "インデックス",
      en: "Index",
    },
    name: {
      ja: "名称",
      en: "Name",
    },
    apiName: {
      ja: "API名称",
      en: "API Name",
    },
    mapId: {
      ja: "マップID",
      en: "Map ID",
    },
    area: {
      ja: "エリア",
      en: "Area ",
    },
    error: {
      index: {
        ja: "インデックスをご入力ください",
        en: "Please enter an index",
      },
      name: {
        ja: "名称をご入力ください",
        en: "Please enter a name",
      },
      mapId: {
        ja: "マップIDをご入力ください",
        en: "Please enter a map ID",
      },
      indexExists: {
        ja: "インデックスが既に存在する",
        en: "Index has already exists",
      },
      areaId: {
        ja: "エリアIDをご入力ください",
        en: "Please enter an area ID",
      },
      areaName: {
        ja: "エリア名称をご入力ください",
        en: "Please enter an area name",
      },
      apiName: {
        ja: "エリアAPI名称をご入力ください",
        en: "Please enter an area API name",
      },
      areaIdExists: {
        ja: "固有なエリアIDをご入力ください",
        en: "Please enter a unique area ID",
      },
      startTime: {
        ja: "開始時間をご入力ください",
        en: "Please enter a start time",
      },
      endTime: {
        ja: "終了時間をご入力ください",
        en: "Please enter an end time",
      },
      endBeforeStart: {
        ja: "開始終了時間より、終了時間の方が早い",
        en: "End time starts before start time",
      },
    },
    addAreaButton: {
      ja: "エリアを追加",
      en: "Add Area",
    },
    restrictedTime: {
      ja: "禁止時間",
      en: "Restricted Time",
    },
    start: {
      ja: "開始",
      en: "Start",
    },
    end: {
      ja: "終了",
      en: "End",
    },
    cleanAfterRestriction: {
      ja: "禁止時間明け清掃",
      en: "Clean After Restriction",
    },
  },
  robotFloorArea: {
    item: {
      ja: "項目",
      en: "Item",
    },
    content: {
      ja: "内容",
      en: "Content",
    },
    hour: {
      ja: "HH時",
      en: "hh A",
    },
    name: {
      ja: "名称",
      en: "Name",
    },
    apiName: {
      ja: "API名称",
      en: "API Name",
    },
    restrictedTime: {
      ja: "禁止時間",
      en: "Restricted Time",
    },
    cleanAfterRestriction: {
      ja: "禁止時間開け清掃",
      en: "Clean After Restriction",
    },
  },
  atp: {
    description: {
      ja: "ATP検査計測ポイント",
      en: "ATP Testing Spot",
    },
    table: {
      type: {
        ja: "タイプ",
        en: "Type",
      },
      name: {
        ja: "名称",
        en: "Name",
      },
      testLocation: {
        ja: "計測場所",
        en: "Testing Spot",
      },
      value: {
        ja: "APTスコア",
        en: "ATP Score",
      },
      empty: {
        ja: "ATPがありません",
        en: "No ATPs",
      },
    },
  },
  atpAdd: {
    title: {
      ja: "ATP計測スポットを追加する",
      en: "Add ATP Testing Spot",
    },
    name: {
      ja: "名称",
      en: "Name",
    },
    description: {
      ja: "ATP計測スポットを追加する",
      en: "Add ATP Testing Spot",
    },
    testLocation: {
      ja: "計測場所",
      en: "Testing Spot",
    },
    manufacturer: {
      ja: "製造メーカー",
      en: "Manufacturer",
    },
    memo: {
      ja: "メモ",
      en: "Memo",
    },
    error: {
      name: {
        ja: "名称をご入力ください",
        en: "Please enter a name",
      },
      testLocation: {
        ja: "計測場所をご入力ください",
        en: "Please enter a testing spot",
      },
    },
    button: {
      ja: "ATP計測スポットを追加する",
      en: "Add ATP Testing Spot",
    },
  },
  atpDetail: {
    dialog: {
      delete: {
        title: {
          ja: "ATP画像を削除",
          en: "Delete ATP Image",
        },
        message: {
          ja: "本当に削除してもよいですか？",
          en: "Are you sure?",
        },
      },
    },
    table: {
      category: {
        ja: "項目",
        en: "Category",
      },
      content: {
        ja: "内容",
        en: "Content",
      },
      name: {
        ja: "名称",
        en: "Name",
      },
      testLocation: {
        ja: "計測場所",
        en: "Testing Spot",
      },
      value: {
        ja: "現在ATPスコア",
        en: "Current ATP Score",
      },
      coordinates: {
        ja: "座標",
        en: "Coordinates",
      },
      manufacturer: {
        ja: "製造メーカー",
        en: "Manufacturer",
      },
      memo: {
        ja: "メモ",
        en: "Memo",
      },
      images: {
        ja: "画像",
        en: "Images",
      },
    },
    history: {
      title: {
        ja: "ATPスコア履歴",
        en: "ATP Score History",
      },
      table: {
        value: {
          ja: "ATPスコア",
          en: "ATP Score",
        },
        dateTime: {
          ja: "日時",
          en: "Date and Time",
        },
        showDashboard: {
          ja: "ダッシュボードに表示する",
          en: "Show on Dashboard",
        },
      },
    },
    delete: {
      ja: "本当にATPを削除してよいですか？",
      en: "Are you sure you want to delete this ATP?",
    },
  },
  atpCreate: {
    title: {
      ja: "ATP履歴を追加する",
      en: "Add ATP History",
    },
    table: {
      value: {
        ja: "ATPスコア",
        en: "ATP Score",
      },
      image: {
        ja: "画像",
        en: "Image ",
      },
    },
    button: {
      ja: "ATP履歴を追加",
      en: "Add ATP History",
    },
    error: {
      score: {
        ja: "ATPスコアをご入力ください",
        en: "Please enter an ATP score",
      },
    },
  },
  facility: {
    description: {
      ja: "この施設情報",
      en: "About this facility.",
    },
  },
  qr: {
    delete: {
      ja: "本当に二次元バーコードを削除してよいですか？",
      en: "Are you sure you want to delete this 2D barcode?",
    },
    dialog: {
      delete: {
        title: {
          ja: "二次元バーコードを削除",
          en: "Delete 2D barcode",
        },
        message: {
          ja: "本当に削除してもよいですか？",
          en: "Are you sure?",
        },
      },
    },

    displayState: {
      en: "Updated successfully",
      ja: "更新しました。",
    },

    description: {
      ja: "この施設に紐づけられた二次元バーコード",
      en: "2D barcodes associated with this facility.",
    },
    qr: {
      ja: "二次元バーコード",
      en: "2D barcode",
    },
    robotAreaInfo: {
      ja: "ロボットエリア情報",
      en: "Robot Area Information",
    },
    address: {
      ja: "通知先",
      en: "Assignee",
    },
    request: {
      ja: "依頼事項",
      en: "Request",
    },
    qrResquestTitle: {
      QrSurveyTitleHeading: {
        ja: "リクエストタイトル",
        en: "Request Title",
      },
      commentHeading: {
        ja: "コメント必須",
        en: "Comment Required",
      },
      CommentPlaceHolder: {
        ja: "コメント内容",
        en: "Comment Contents",
      },
    },

    place: {
      ja: "設置場所",
      en: "Place",
    },
    qrList: {
      ja: "二次元バーコード一覧",
      en: "List",
    },
    disable: {
      ja: "無効化",
      en: "Turn off",
    },
    add: {
      ja: "新規追加",
      en: "Create",
    },
    generate: {
      ja: "生成",
      en: "Generate",
    },
    view: {
      ja: "表示",
      en: "View",
    },
    download: {
      ja: "ダウンロード",
      en: "Download",
    },
    csv: {
      summaryHeader: {
        ja: "呼び出し件数の集計結果",
        en: "Call Count Summary",
      },
      dayOfWeekHeader: {
        ja: "曜日ごとの件数",
        en: "Count by Day of the Week",
      },
      placeHeader: {
        ja: "設置場所ごとの件数",
        en: "Count by Place",
      },
      total: {
        ja: "合計",
        en: "Total",
      },
      grouping: {
        ja: "設置場所グループ化",
        en: "Group by Place",
      },
      noData: {
        ja: "二次元バーコードのデータがありません",
        en: "No 2D Barcode Data",
      },
      noDataSurvey: {
        ja: "アンケートのデータがありません",
        en: "No Survey Data",
      },
    },
    access: {
      ja: "アクセス",
      en: "Access",
    },
    none: {
      ja: "二次元バーコードがありません",
      en: "No 2D barcode",
    },
    settings: {
      title: {
        ja: "設定",
        en: "Settings",
      },
      content: {
        ja: "ロゴを設定してください",
        en: "Please set the logo",
      },
      qrRequestColor: {
        ja: "二次元バーコードリクエスト背景色",
        en: "2D Barcode Request Background Color",
      },
      backgroundColor: {
        ja: "背景色",
        en: "Background Color",
      },
      reset: {
        button: {
          ja: "リセット",
          en: "Reset",
        },
        title: {
          ja: "背景色リセット",
          en: "Reset Background Color",
        },
        message: {
          ja: "本当にリセットしてもよいですか？",
          en: "Are you sure?",
        },
      },
    },
    survey: {
      warning: {
        en: "When displaying a survey, please select at least one survey question.",
        ja: "アンケートを表示する場合は、アンケート文言を1つは選択して下さい。",
      },
      title: {
        ja: "アンケート",
        en: "Survey",
      },
      deleteQuestion: {
        ja: "本当に文言を削除してよいですか？",
        en: "Are you sure you want to delete this survery question?",
      },
    },
    limit: {
      ja: "ファイルは10MB以内のPNGまたはJPEGファイルである必要があります。(10MB以内)",
      en: "Files must be PNG or JPEG files of 10 MB or less. (within 10MB)",
    },
    noFile: {
      ja: "設定されていません",
      en: "Not set",
    },
    delete: {
      ja: "削除してよろしいですか？",
      en: "Are you sure you want to delete it?",
    },
    detail: {
      place: {
        ja: "場所",
        en: "Place",
      },
      request: {
        ja: "リクエスト",
        en: "Request",
      },
      assignee: {
        ja: "通知先",
        en: "Assignee",
      },
      surveyQuestions: {
        ja: "アンケート文言",
        en: "Survey Questions",
      },
      cleaningMode: {
        ja: "ロボットクリーニングモード",
        en: "Robot Cleaning Mode",
      },
      robotAreas: {
        ja: "ロボットエリア",
        en: "Robot Areas",
      },
      requestPage: {
        ja: "リクエストページ",
        en: "Request Page",
      },
      saveButton: {
        ja: "画像を保存する",
        en: "Save Image",
      },
      warning: {
        ja: "この二次元バーコードは一時停止中です",
        en: "This 2D barcode has been suspended",
      },
      noAssignee: {
        ja: "通知先を選択してください",
        en: "Please select an assignee",
      },
      displayRequest: {
        ja: "リクエストを表示",
        en: "Display Request",
      },
      displaySurvey: {
        ja: "アンケートを表示",
        en: "Display Survey",
      },
      displayWarning: {
        ja: "リクエスト表示又はアンケート表示を有効にしてください。",
        en: "Please activate the request display or the survey display.",
      },
      completePageMessage: {
        ja: "完了ページ文言",
        en: "Complete Page Message",
      },
    },
    canSurvey: {
      ja: "集計ページ",
      en: "Survey Page",
    },
    surveyQuestions: {
      ja: "文言",
      en: "Questions",
    },
  },
  qrAdd: {
    title: {
      ja: "二次元バーコードを追加する",
      en: "Generate 2D barcode",
    },
    description: {
      ja: "二次元バーコードを追加する",
      en: "Generate 2D barcode",
    },
    target: {
      ja: "通知先",
      en: "Target",
    },
    request: {
      ja: "依頼事項",
      en: "Request",
    },
    place: {
      ja: "設置場所",
      en: "Place",
    },
    generate: {
      ja: "生成",
      en: "Generate",
    },
    addTask: {
      ja: "依頼事項を追加する",
      en: "Add a request",
    },
    cancel: {
      ja: "キャンセル",
      en: "Cancel",
    },
    ok: {
      ja: "OK",
      en: "OK",
    },
  },
  map: {
    dashboardTableName: {
      ja: "名前",
      en: "Name",
    },
    dashboardTableType: {
      ja: "種別",
      en: "Type",
    },
    dashboardTableValue: {
      ja: "現在値",
      en: "Value",
    },
    edit: {
      ja: "編集",
      en: "Edit",
    },
    delete: {
      ja: "削除",
      en: "Delete",
    },
    add: {
      title: {
        ja: "デバイスを追加する",
        en: "Add Device",
      },
      table: {
        name: {
          ja: "名前",
          en: "Name",
        },
        type: {
          ja: "種別",
          en: "Type",
        },
        coordinates: {
          ja: "座標",
          en: "Coordinates",
        },
        confirm: {
          ja: "決定",
          en: "Confirm",
        },
        cancel: {
          ja: "キャンセル",
          en: "Cancel",
        },
      },
    },
    dialog: {
      yesNo: {
        title: {
          ja: "マップから削除します",
          en: "Delete from this map",
        },
        message: {
          ja: "本当に削除してもよいですか？",
          en: "Are you sure?",
        },
      },
      plot: {
        title: {
          ja: "ATP/センサーを追加する",
          en: "Add ATP/Sensor",
        },
        type: {
          ja: "種別",
          en: "Type",
        },
      },
    },
    noName: {
      ja: "名称未設定",
      en: "Name Not Set",
    },
  },
  maps: {
    description: {
      ja: "マップを編集する",
      en: "Edit Maps",
    },
    noMaps: {
      ja: "マップが登録されていません",
      en: "No Maps",
    },
    noCoordinates: {
      ja: "座標がありません",
      en: "No Coordinates",
    },
  },
  mapsEdit: {
    title: {
      ja: "マップを編集する",
      en: "Edit Map",
    },
  },
  mapsAdd: {
    title: {
      ja: "マップを追加する",
      en: "Add Map",
    },
  },
  mapsForm: {
    floorName: {
      ja: "フロア名称",
      en: "Floor Name",
    },
    uploadImage: {
      ja: "マップ画像アップロード",
      en: "Upload Map Image",
    },
    noImage: {
      ja: "選択されていません",
      en: "Not Selected",
    },
    imageButton: {
      ja: "画像を選択",
      en: "Select an Image",
    },
    delete: {
      ja: "削除",
      en: "Delete",
    },
    add: {
      ja: "追加",
      en: "Add",
    },
    edit: {
      ja: "編集",
      en: "Edit",
    },
    error: {
      floorName: {
        ja: "フロア名称をご入力ください",
        en: "Please enter a floor name",
      },
    },
    dialog: {
      yesNo: {
        title: {
          ja: "マップを削除する",
          en: "Delete Map",
        },
        message: {
          ja: "本当に削除してもよろしいでしょうか。",
          en: "Are you sure?",
        },
      },
    },
  },
  request: {
    noRequestTitles: {
      ja: "デフォルトの文言になっています",
      en: "No Content",
    },
    chooseRequest: {
      ja: "ご依頼事項を選択してください",
      en: "Please choose from the list below.",
    },
    uploadPicture: {
      ja: "写真をアップロードする(任意)",
      en: "Upload a picture.(optional)",
    },
    uploadPictureBtn: {
      ja: "写真を選択",
      en: "Select a picture",
    },
    commentField: {
      ja: "コメント(任意)",
      en: "Note(optional)",
    },
    thanks: {
      ja: "ご依頼ありがとうございました。担当にて対応いたします。",
      en: "Thank you for your request. The person in charge will respond.",
    },
    send: {
      ja: "送信",
      en: "Send",
    },
    robotCleaning: {
      ja: "ロボット清掃",
      en: "Robot Cleaning",
    },
    survey: {
      pleaseAnswer: {
        ja: "アンケートにお答えいただきますよう、ご協力をお願いいたします。",
        en: "Please fill out our survey.",
      },
    },
    noPage: {
      ja: "この二次元バーコードは利用できません。",
      en: "This 2D barcode is not available.",
    },
  },
  reports: {
    addTask: {
      ja: "清掃作業を追加",
      en: "Add Task",
    },
    addExecutedTask: {
      ja: "実施済み清掃作業を追加",
      en: "Add Completed Task",
    },
    generateTheDay: {
      ja: "この日のレポートを出力",
      en: "Generate This Day's Report",
    },
    generateTheDayBeacon: {
      ja: "行動実績含むレポート",
      en: "Generate Activity Records",
    },
    progress: {
      ja: "進捗状況",
      en: "Progress",
    },
    download: {
      ja: "ダウンロード",
      en: "Download",
    },
    deleteDialog: {
      title: {
        ja: "タスクを施設から削除します",
        en: "Delete task from this facility",
      },
      message: {
        ja: "本当に削除してもよいですか？",
        en: "Are you sure?",
      },
    },
  },
  reportsAdd: {
    title: {
      ja: "清掃作業を追加する",
      en: "Add Task",
    },
    assignee: {
      ja: "担当者",
      en: "Assignee",
    },
    request: {
      ja: "依頼事項",
      en: "Request",
    },
    location: {
      ja: "場所",
      en: "Location",
    },
    memo: {
      ja: "メモ",
      en: "Memo",
    },
    repeat: {
      title: {
        ja: "繰り返し",
        en: "Repeat",
      },
      weekTitle: {
        ja: "繰り返しの予定日",
        en: "Repeated Days",
      },
      0: {
        ja: "繰り返さない",
        en: "No Repeat",
      },
      1: {
        ja: "日",
        en: "Daily",
      },
      2: {
        ja: "週",
        en: "Weekly",
      },
    },
    weekDay: {
      1: {
        ja: "月",
        en: "MON",
      },
      2: {
        ja: "火",
        en: "TUE",
      },
      3: {
        ja: "水",
        en: "WED",
      },
      4: {
        ja: "木",
        en: "THU",
      },
      5: {
        ja: "金",
        en: "FRI",
      },
      6: {
        ja: "土",
        en: "SAT",
      },
      7: {
        ja: "日",
        en: "SUN",
      },
    },
    date: {
      ja: "日付",
      en: "Date",
    },

    startDate: {
      ja: "開始日",
      en: "Start Date",
    },
    endDate: {
      ja: "終了日",
      en: "End Date",
    },
    time: {
      ja: "開始時間",
      en: "Start Time",
    },
    timeEnd: {
      ja: "終了時間",
      en: "End Time",
    },
    ampm: {
      ja: false,
      en: true,
    },
    button: {
      ja: "清掃計画を追加",
      en: "Add Task",
    },
    error: {
      assignee: {
        ja: "担当者を選択してください",
        en: "Please select an Assignee",
      },
      timeSame: {
        ja: "終了時刻は開始時刻と同じにしないでください。",
        en: "The ending time sohuld not be same as the starting time.",
      },
      request: {
        ja: "依頼事項を選択してください",
        en: "Please select a request",
      },
      location: {
        ja: "場所をご入力ください",
        en: "Please enter a location",
      },
      taskMemo: {
        ja: "メモをご入力ください",
        en: "Please enter a memo",
      },
      repeat: {
        ja: "繰り返しを選択してください",
        en: "Please select whether or not to repeat",
      },
      days: {
        ja: "繰り返しの予定日を選択してください",
        en: "Please select repeated days",
      },
      date: {
        ja: "日付を選択してください",
        en: "Please select a date",
      },
      startDate: {
        ja: "開始日を選択してください",
        en: "Please select a start date",
      },
      endDate: {
        ja: "終了日を選択してください",
        en: "Please select an end date",
      },
      time: {
        ja: "時間を選択してください",
        en: "Please select a time",
      },
      endDateBefore: {
        ja: "開始日以降の終了日を選択してください",
        en: "Please select an end date after the start date",
      },
      endTImeBefore: {
        ja: "開始時間以降の終了時間を選択してください",
        en: "Please select an end time after the start time",
      },
      reportOutline: {
        ja: "作業内容・所要時間をご入力ください",
        en: "Please enter task content・time required",
      },
    },
    complete: {
      title: {
        ja: "実施済み清掃作業を追加する",
        en: "Add Completed Task",
      },
      button: {
        ja: "実施済み清掃を追加",
        en: "Add Completed Task",
      },
      reportOutline: {
        ja: "作業内容・所要時間",
        en: "Task Content・Time Required",
      },
    },
  },
  reportsEdit: {
    title: {
      ja: "清掃作業を編集する",
      en: "Edit Task",
    },
    button: {
      ja: "清掃計画を編集",
      en: "Edit Task",
    },
  },
  tasks: {
    status: {
      false: {
        ja: "未完了",
        en: "Incomplete",
      },
      true: {
        ja: "完了",
        en: "Completed",
      },
      inProgress: {
        ja: "対応中 ",
        en: "In Progress",
      },
    },
    titles: {
      type: {
        ja: "種類",
        en: "Type",
      },
      location: {
        ja: "場所",
        en: "Location",
      },
      contents: {
        ja: "内容",
        en: "Contents",
      },
      createdAt: {
        ja: "日時",
        en: "Date and Time",
      },
      completionStatus: {
        ja: "完了状態",
        en: "Completion Status",
      },
      completedBy: {
        ja: "完了した担当者",
        en: "Completed By",
      },
      completedRobot: {
        ja: "完了したロボット",
        en: "Completed Robot",
      },
      assignee: {
        ja: "担当者",
        en: "Assingee",
      },
      taskStatus: {
        ja: "状況",
        en: "Status",
      },

      assignedTo: {
        ja: "対応中の担当者",
        en: "Assigned To",
      },
      alreadyAssigned: {
        ja: "既に自分に割り当たってます。",
        en: "You have already assigned this task to yourself.",
      },
      alreadyAssignedToAnother: {
        ja: "タスクはすでに別のユーザーに割り当てられています",
        en: "Task has been already assigned to another user.",
      },
      assignedToCurrent: {
        ja: "タスクが自分に割り当てられました。",
        en: "The task has been assigned to you successfully.",
      },
      assignedToNone: {
        ja: "-",
        en: "No user currently assigned",
      },
      taskMemo: {
        ja: "メモ",
        en: "Memo",
      },
      reportOutline: {
        ja: "作業内容・所要時間",
        en: "Task Content・Time Required",
      },
      requestCleaningImage: {
        ja: "掃除リクエスト画像",
        en: "Cleaning Request Image",
      },
      completedCleaniningImage: {
        ja: "掃除完了画像",
        en: "Completed Cleaning Image",
      },
      requestImageUrl: {
        ja: "掃除リクエスト画像URL",
        en: "Cleaning Request Image URL",
      },
      reportImageUrl: {
        ja: "掃除レポート画像URL",
        en: "Cleaning Report Image URL(s)",
      },
      robotImage: {
        ja: "	ロボット清掃画像",
        en: "Robot Cleaning Image",
      },
    },
    image: {
      request: {
        ja: "リクエスト",
        en: "Request",
      },
      report: {
        ja: "レポート",
        en: "Report",
      },
      title: {
        ja: "写真をアップロードする（任意）",
        en: "Upload an Image (Optional)",
      },
      button: {
        ja: "写真を選択",
        en: "Select an Image",
      },
      helper: {
        ja: "PNGまたはJPEGファイルである必要があります",
        en: "PNG OR JPEG File Required",
      },
      png: {
        ja: "PNGまたはJPEGファイルを選択してください",
        en: "Please select a PNG OR JPEG file ",
      },
    },
  },
  cleaners: {
    notExist: {
      ja: "タスクは存在しません",
      en: "Task Does Not Exist",
    },
    title: {
      ja: "未完了の作業",
      en: "Incomplete Tasks",
    },
    subtitle: {
      ja: "自分に割り当てられている作業を表示します。",
      en: "Showing tasks assigned to you.",
    },
    details: {
      ja: "詳細を見る",
      en: "View Details",
    },
    return: {
      ja: "一覧に戻る",
      en: "Back to Index",
    },
    assignee: {
      self: {
        ja: "自分に割り当てられています",
        en: "Assigned to you",
      },
      others: {
        ja: "ほかの人に割り当てられています",
        en: "Assigned to others",
      },
    },
    report: {
      ja: "報告する",
      en: "Report",
    },
    robot: {
      ja: "ロボットスタート",
      en: "Start robot",
    },
    fill: {
      ja: "ここに記入してください",
      en: "Fill out the report here",
    },
  },
  cleaner: {
    attendanceTitle: {
      ja: "出退勤",
      en: "Attending and leaving work",
    },
    attendance: {
      ja: "出勤する",
      en: "Attendance at work",
    },
    leave: {
      ja: "退勤する",
      en: "Leave work",
    },
    attendanceAlert: {
      ja: "出勤しました",
      en: "Attendance",
    },
    leaveAlert: {
      ja: "退勤しました",
      en: "Leave work",
    },
  },
  facilitySettings: {
    nps: {
      subTitle: {
        en: "Indicators that measure whether facility users would recommend a facility",
        ja: "施設利用者が施設を推薦するかを測る指標",
      },
      feedback: {
        en: "Feedback",
        ja: "フィードバック",
      },
      feedbackAll: {
        en: "Overall Feedback",
        ja: "全体フィードバック",
      },
      feedbackPositive: {
        en: "Positive Feedback",
        ja: "肯定的フィードバック",
      },
      feedbackNegative: {
        en: "Negative Feedback",
        ja: "否定的フィードバック",
      },
      changedServices: {
        en: "Changed Services",
        ja: "変化のあったサービス",
      },
    },

    title: {
      ja: "施設設定",
      en: "Facility Settings",
    },
    description: {
      ja: "施設設定を管理する",
      en: "Manage Facility Settings",
    },
    customerSatisfactionCsvUpload: {
      title: {
        ja: "顧客満足度CSVのアップロード",
        en: "Customer Satisfaction CSV Upload",
      },
      select: {
        ja: "ファイルを選択してください",
        en: "Select a File",
      },
      upload: {
        ja: "アップロードする",
        en: "Upload",
      },

      alert: {
        fileType: {
          ja: "Zipファイルを選択してください",
          en: "Please select a Zip file",
        },

        uploadError: {
          ja: "ファイルがアップロードされませんでした",
          en: "File(s) not uploaded",
        },
        uploadSuccess: {
          ja: "ファイルが正常にアップロードされました",
          en: "File(s) were successfully uploaded",
        },
      },
    },
    Co2Title: {
      ja: "温室効果ガス排出量",
      en: "Greenhouse Gas Emissions",
    },

    CO2Dashboard: {
      subtitle: {
        ja: "当たりの温室効果ガスの排出量",
        en: "Greenhouse Gas Emissions",
      },
    },
    Co2Error: {
      ja: "二酸化炭素は1以上の数値で登録して下さい",
      en: "Please register the number of CO2 as a value of 1 or more",
    },
    MeterError: {
      ja: "平米数は1以上の数値で登録して下さい",
      en: "Please register the number of square meters as a value of 1 or more",
    },
    tableRobot: {
      value: {
        en: "value",
        ja: "値",
      },
      person: {
        en: "Updated By",
        ja: "更新者",
      },

      date: {
        ja: "日時",
        en: "Date",
      },
    },

    upload: {
      en: "Upload File",
      ja: "ファイルアップロード",
    },

    RobotCleaning: {
      title: {
        ja: "ロボット清掃面積CSVのアップロード",
        en: "Robot Floor Coverage CSV Upload",
      },
      fileTypeRobot: {
        ja: "CSVファイルを選択してください",
        en: "Please select a CSV file",
      },
      date: {
        ja: "日",
        en: "Date",
      },
      time: {
        ja: "時",
        en: "Time",
      },

      errorDelete: {
        en: "Error deleting document",
        ja: "ドキュメントの削除エラー",
      },

      noFIle: {
        en: "Could not upload the file.",
        ja: "ファイルをアップロードできませんでした。",
      },
      selectFile: {
        ja: "CSVファイルを選択してください",
        en: "Please select a CSV file",
      },
      Delete: {
        ja: "削除されました",
        en: "Successfully Deleted",
      },
      submit: {
        en: "Complete",
        ja: "完了",
      },
    },

    operationKpi: {
      robotCleaning: {
        ja: "ロボット清掃面積",
        en: "Robot Floor Coverage",
      },
    },

    sensorRegister: {
      noSensorsFound: {
        en: "No motion sensors were found for the current facility.",
        ja: "現在の施設ではセンサーが見つかりませんでした。",
      },
      title: {
        en: "Register Sensors",
        ja: "センサー登録",
      },
      sensorSelectLimit: {
        en: "You can select up to 5 sensors only.",
        ja: "最大5つのセンサーのみを選択できます。",
      },
      sensorRegisterationComplete: {
        en: "Sensor registeration complete.",
        ja: "センサー登録完了をしました。",
      },
    },
    qrRequests: {
      title: {
        ja: "二次元バーコードリクエスト",
        en: "2D Barcode Requests",
      },
    },

    requests: {
      new: {
        ja: "新規リクエスト",
        en: "New Request",
      },
      delete: {
        ja: "本当にリクエストを削除してもよいですか。",
        en: "Are you sure you want to delete this request",
      },

      manageRequests: {
        ja: "依頼事項管理",
        en: "Request Management",
      },
    },
  },

  surveyQuestionsHeader: {
    title: {
      ja: ["日付", "設置場所", "アンケート", "星数"],
      en: ["date", "place", "Survey Questions", "rating"],
    },
  },

  whatsAppRegisteration: {
    completeTaskNotif: {
      en: "Task completion notification",
      ja: "タスク完了時の通知",
    },
    phoneLabel: {
      en: "Phone Number",
      ja: "電話番号",
    },
    register: {
      ja: "WhatsAppを変更",
      en: "Edit WhatsApp",
    },
    registerButton: {
      ja: "WhatsAppを変更",
      en: "Edit WhatsApp",
    },
    notifications: {
      en: "Notifications",
      ja: "通知",
    },
    fail: {
      en: "Failed to add Phone Number.",
      ja: "電話番号の追加に失敗しました。",
    },

    success: {
      en: "Updated successfully!",
      ja: "更新しました。",
    },
    success2: {
      en: "Successfully updated. Please check the saved phone number again in case of no notifications. ",
      ja: "変更しました。通知が飛ばない時は電話番号を確認ください。",
    },
    numberExistsError: {
      en: "Update failed. Phone number is already registered.",
      ja: "更新に失敗しました。電話番号は既に登録されています。",
    },

    valid: {
      en: "Please enter your valid Phone number.",
      ja: "有効な電話番号をご入力ください。",
    },
    noEntry: {
      en: "Please enter your Phone Number.",
      ja: "電話番号をご入力ください。",
    },
  },
  sensorDownload: {
    HeadingFormat: {
      sensorId: {
        ja: "センサーID",
        en: "SensorID",
      },
      value: {
        ja: "値",
        en: "value",
      },

      inCharge: {
        ja: "担当者",
        en: "In Charge",
      },
      resultsHeading1: {
        ja: "行動実績",
        en: "Action Results",
      },
      place: {
        ja: "場所",
        en: "place",
      },
      taskStart: {
        ja: "作業開始",
        en: "Start Time",
      },
      taskEnd: {
        ja: "作業終了",
        en: "End Time",
      },
      resultsHeading2: {
        ja: "タスク実績",
        en: "Task Results",
      },
      date1: {
        ja: "日付1",
        en: "Date1",
      },
      date: {
        ja: "日付",
        en: "Date",
      },
      date2: {
        ja: "日付2",
        en: "Date2",
      },
    },
    csv: {
      long: {
        en: "MM-DD-YYYY HH:mm:ss",
        ja: "YYYY-MM-DD HH:mm:ss",
      },
      short: {
        en: "MM-DD-YYYY",
        ja: "YYYY-MM-DD",
      },
    },
    dateRange: {
      en: "Please select a Time Period.",
      ja: "期間を選択してください。",
    },
    selectSensor: {
      en: "Please select sensors to Download.",
      ja: "ダウンロードするセンサーを選択してください。",
    },
    limitDays: {
      en: "Please select a date range within 31 days.",
      ja: "31日以内の日付を選択してください。",
    },
  },
  languages: {
    ja: "JP",
    en: "EN",
  },
};

export default Basic;
