import { FormHelperText } from "@mui/material";
import Typography from "../parts-ui/Typography";
import { getNameByUid } from "./getUser";
import BasicLanguage from "../languages/Basic";

export const CheckForUsers = ({ facility, assignees, language }) => {
  const validAssignees =
    assignees?.filter((assignee) => {
      const name = getNameByUid(facility, assignee);

      return typeof name === "string" && name.trim().length > 0;
    }) || [];

  if (validAssignees.length > 0) {
    return validAssignees.map((assignee, index) => {
      const name = getNameByUid(facility, assignee);
      return <Typography key={assignee.uid || index}>{name}</Typography>;
    });
  }

  return (
    <Typography>
      <FormHelperText style={{ color: "rgb(211, 47, 47)" }}>
        {BasicLanguage.qr.detail.noAssignee[language]}
      </FormHelperText>
    </Typography>
  );
};
