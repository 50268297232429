import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { SbxBarChartLib } from "../SbxChart";
import BasicLanguage from "../../languages/Basic";
import { useRecoilValue } from "recoil";
import languageState from "../../../recoil/atoms/languageState";
import moment from "moment";
import PropTypes from "prop-types";
import { getWeekNumber } from "../../utils/GetWeeks";

export default function PeopleCountAllChart({
  date,
  dateDisplay,
  peopleCountAllData,
}) {
  const language = useRecoilValue(languageState);

  if (dateDisplay === "day") {
    peopleCountAllData = generateDataByDay(peopleCountAllData, language, date);
  } else if (dateDisplay === "week") {
    peopleCountAllData = generateDataByWeek(peopleCountAllData, language, date);
  } else if (dateDisplay === "month") {
    peopleCountAllData = generateDataByMonth(
      peopleCountAllData,
      language,
      date
    );
  }

  return (
    <Stack sx={{ width: "100%" }} justifyContent="center">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "1.2em",
        }}
      >
        {BasicLanguage.dashboard.kpi.peopleCountAll.title[language]}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ width: "100%", height: "300px" }}>
        <SbxBarChartLib
          data={peopleCountAllData}
          dataKey={BasicLanguage.dashboard.kpi.peopleCountAll.dataKey[language]}
        />
      </Box>
    </Stack>
  );
}

PeopleCountAllChart.propTypes = {
  date: PropTypes.object.isRequired,
  dateDisplay: PropTypes.string.isRequired,
  peopleCountAllData: PropTypes.array.isRequired,
};

const generateDataByDay = (sensorHistory, language, date) => {
  const defaultDays = {};
  for (let i = 1; i <= moment(date).daysInMonth(); i++) {
    defaultDays[i] = 0;
  }

  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      const historyDate = moment(history.createdAt).date();
      sensorHistoryObject[historyDate] =
        (sensorHistoryObject[historyDate] || 0) + history.value;
      return sensorHistoryObject;
    },
    defaultDays
  );

  return Object.keys(sensorHistoryPeopleCount)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.peopleCountAll.dataKey[language]]:
          sensorHistoryPeopleCount[key],
      };
    });
};

const generateDataByWeek = (sensorHistory, language, date) => {
  if (!(date instanceof Date)) {
    try {
      date = new Date(date);
    } catch (e) {
      console.error("Invalid date provided to generateDataByWeek:", date);
      throw new Error("Invalid date provided to generateDataByWeek");
    }
  }

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const firstWeek = getWeekNumber(startOfMonth);
  const lastWeek = getWeekNumber(endOfMonth);

  const defaultWeeks = {};
  for (let i = firstWeek; i <= lastWeek; i++) {
    defaultWeeks[i === 0 ? 1 : i] = 0;
  }

  // Process sensor history
  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      if (!history.createdAt) {
        console.error("Invalid createdAt in sensor history:", history);
        return sensorHistoryObject;
      }
      const historyDate = new Date(history.createdAt);
      if (isNaN(historyDate.getTime())) {
        console.error("Invalid history date:", history);
        return sensorHistoryObject;
      }

      const week = getWeekNumber(historyDate);

      if (defaultWeeks[week] !== undefined) {
        sensorHistoryObject[week] =
          (sensorHistoryObject[week] || 0) + (history.value ?? 1);
      }
      return sensorHistoryObject;
    },
    defaultWeeks
  );

  const mappedWeeks = Object.keys(sensorHistoryPeopleCount)
    .map((weekString) => parseInt(weekString, 10)) // Convert to int
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        name: key,
        [BasicLanguage.dashboard.kpi.peopleCountAll.dataKey[language]]:
          sensorHistoryPeopleCount[key],
      };
    });

  return mappedWeeks;
};

const generateDataByMonth = (sensorHistory, language, date) => {
  const sensorHistoryPeopleCount = sensorHistory.reduce(
    (sensorHistoryObject, history) => {
      sensorHistoryObject.month += history.value;
      return sensorHistoryObject;
    },
    { month: 0 }
  );

  return Object.keys(sensorHistoryPeopleCount).map((key) => {
    return {
      name: moment(date).format(
        BasicLanguage.dashboard.kpi.peopleCountAll.yearMonthFormat[language]
      ),
      [BasicLanguage.dashboard.kpi.peopleCountAll.dataKey[language]]:
        sensorHistoryPeopleCount[key],
    };
  });
};
